import axios from "axios";
import {ApiHost} from "../constants";

export default {
  state: {
    repairList: [],
    selectedCarRepairItem: {},
    carRepairItemList: [],
    searchRepairItemName: "",
    repairInfo: {},
    startDay: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().substr(0, 10),
    endDay: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString().substr(0, 10),
  },
  getters: {
    getRepairList: (state) => {
      return state.repairList;
    },
    getCarRepairItemList: (state) => {
      return state.carRepairItemList;
    },
    getSelectedCarRepairItem: (state) => {
      return state.selectedCarRepairItem;
    },
    getStartDay: (state) => {
      return state.startDay;
    },
    getEndDay: (state) => {
      return state.endDay;
    },
    getRepairInfo: (state) => {
      return state.repairInfo;
    },
    getSearchRepairItemName: (state) => {
      return state.searchRepairItemName;
    }
  },
  mutations: {
    setRepairList: (state, payload) => {
      state.repairList = payload;
    },
    setStartDay: (state, payload) => {
      state.startDay = payload;
    },
    setEndDay: (state, payload) => {
      state.endDay = payload;
    },
    setSelectedCarRepairItem: (state, payload) => {
      state.selectedCarRepairItem = payload;
    },
    clearStartDay: (state) => {
      state.startDay = new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().substr(0, 10)
    },
    clearEndDay: (state) => {
      state.endDay = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString().substr(0, 10)
    },
    setCarRepairItemList: (state, payload) => {
      state.carRepairItemList = payload;
    },
    setRepairInfo: (state, payload) => {
      state.repairInfo = payload;
    },
    setSearchRepairItemName: (state, payload) => {
      state.searchRepairItemName = payload;
    },
  },
  actions: {
    clearStartDay: ({ commit }) => {
      commit("clearStartDay");
    },
    clearEndDay: ({ commit }) => {
      commit("clearEndDay");
    },
    setStartDay: ({ commit }, payload) => {
      commit("setStartDay", payload);
    },
    setEndDay: ({ commit }, payload) => {
      commit("setEndDay", payload);
    },
    setSelectedCarRepairItem: ({ commit }, payload) => {
      commit("setSelectedCarRepairItem", payload);
    },
    async setRepairList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var repairList = await axios.get(
        `${ApiHost}/moove/api/repair` +
          `/` +
          payload.strDt +
          `/` +
          payload.endDt,
        { params: params }
      );
      commit("setRepairList", repairList.data);
    },
    async setCarRepairItemList({ commit }) {
      var carRepairItemList = await axios.get(
        `${ApiHost}/moove/api/repair/item`
      );
      commit("setCarRepairItemList", carRepairItemList.data);
    },
    async setRepairInfo({commit}, payload) {
      const repairInfo = await axios.get(
          `${ApiHost}/moove/api/repair/${payload}`
      );
      commit("setRepairInfo", repairInfo.data);
    },
    async saveRepairInfo({commit}, payload) {
      await axios.post(`${ApiHost}/moove/api/repair`, payload);
    },
    async deleteRepairInfo({commit}, payload) {
      await axios.delete(`${ApiHost}/moove/api/repair/delete/${payload}`);
    },
    setSearchRepairItemName: ({ commit }, payload) => {
      commit("setSearchRepairItemName", payload);
    },
    async saveRepairItem({commit}, payload) {
      return (await axios.post(`${ApiHost}/moove/api/repair/item/save`, payload)).data;
    },
    async deleteRepairItem({commit}, payload) {
      return (await axios.get(`${ApiHost}/moove/api/repair/item/delete/${payload}`)).data;
    },
    async searchRepairItem({commit}, payload) {
      const response = (await axios.get(`${ApiHost}/moove/api/repair/item/${payload}`)).data;
      commit("setCarRepairItemList", response.data);
      return response;
    },
  },
};
