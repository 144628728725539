import axios from "axios";
import { ApiHost } from "../constants";
import {emptyCheck} from "@/utils/util";

export default {
  state: {
    trpSndEtasList: [],
    trpSndEtasListPC: [],
  },

  getters: {
    getTrpSndEtasList: (state) => {
      return state.trpSndEtasList;
    },
    getTrpSndEtasListPC: (state) => {
      return state.trpSndEtasListPC;
    }
  },

  mutations: {
    setTrpSndEtasList: (state, payload) => {
      state.trpSndEtasList = payload;
    },
    setTrpSndEtasListPC: (state, payload) => {
      state.trpSndEtasListPC = payload;
    }
  },

  actions: {
    async setTrpSndEtasList ({commit}, payload) {
      let url = `${ApiHost}/moove/api/etas/send-history/` +
          `${payload.bizNo}/${payload.strDt}/${payload.endDt}`;
      if (!emptyCheck(payload.carNo)) {
        url = url.concat(`?carNo=${payload.carNo}`);
      }
      const result = await axios.get(url);
      commit("setTrpSndEtasList", result.data);
    },
    async setTrpSndEtasListPC ({commit}, payload) {
      let url = `${ApiHost}/moove/api/etas/send-history-pc/` +
          `${payload.bizNo}/${payload.strDt}/${payload.endDt}?`;
      if (!emptyCheck(payload.carNo)) {
        url = url.concat(`carNo=${payload.carNo}`);
      }
      if (!emptyCheck(payload.dptCd)) {
        if (!emptyCheck(payload.carNo)) {
          url = url.concat(`&`);
        }
        url = url.concat(`dptCd=${payload.dptCd}`);
      }
      const result = await axios.get(url);
      commit("setTrpSndEtasListPC", result.data);
    }
  },
};
