import axios from "axios";
import { ApiHost } from "../constants";

export default {
  namespaced: true,
  state: {
    noticeTypeList: [
      { name: "공지사항", noticeType: "01" },
      { name: "업데이트", noticeType: "02" },
      { name: "작업공지", noticeType: "03" },
      { name: "기타", noticeType: "04" }
    ],
    attFileList: [],
    notice: {},
    noticeList: [],
    preNxtNoticeList: []
  },

  getters: {
    getNotice: state => {
      return state.notice;
    },
    getNoticeList: state => {
      return state.noticeList;
    },
    getAttFileList: state => {
      if (state.attFileList.length == 0) return state.attFileList;
      return state.attFileList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.fileId
        };
        return ele1;
      });
    },
    getNoticeTypeList: state => {
      return state.noticeTypeList;
    },
    getPreNxtNoticeList: state => {
      return state.preNxtNoticeList;
    }
  },

  mutations: {
    setNotice: (state, payload) => {
      var noticeType = state.noticeTypeList.filter(
        n => n.noticeType == payload.noticeType
      );

      state.notice = {
        ...payload,
        noticeTypeNm: noticeType.map(c => c.name)[0]
      };
    },
    setNoticeList: (state, payload) => {
      if (payload == "") payload = [];
      state.noticeList = payload;
    },
    setNoticeTypeList: (state, payload) => {
      state.noticeTypeList = payload;
    },
    setAttFileList: (state, payload) => {
      state.attFileList = payload;
    },
    setPreNxtNoticeList: (state, payload) => {
      state.preNxtNoticeList = payload;
    }
  },

  actions: {
    setNotice: ({ commit }, payload) => {
      axios.get(`${ApiHost}/moove/api/notice/` + payload.noticeId).then(res => {
        commit("setNotice", res.data);
      });
    },
    setNoticeList: ({ commit }) => {
      axios.get(`${ApiHost}/moove/api/notice`).then(res => {
        commit("setNoticeList", res.data);
      });
    },
    setNewNoticeList({ commit }) {
      return axios.get(`${ApiHost}/moove/api/notice/new`)
        .then(res => {
          let noticeData = res.data
          commit('setNoticeList', noticeData);
      })
    },
    async loadNoticeAttFileList({ commit }, payload) {
      var attFileList = await axios.get(
        `${ApiHost}/moove/api/notice/attfile/` + payload.noticeId
      );
      commit("setAttFileList", attFileList.data);
    },
    async setPreNxtNoticeList({ commit }, payload) {
      var preNxtNoticeList = await axios.get(
        `${ApiHost}/moove/api/notice/` + payload.noticeId + "/prenxt"
      );
      commit("setPreNxtNoticeList", preNxtNoticeList.data);
    }
  }
};
