import VueRouter from "vue-router";
import Vue from "vue"
// import store from "../store";
Vue.use(VueRouter)

// 토큰이 확인 된 경우, 다음 layout으로 이동
const hasToken = () => (to, from, next) => {
  // console.log("index.js access_token: " + localStorage.getItem("access_token"))
  if (
    localStorage.getItem("access_token") !== null &&
    localStorage.getItem("access_token") !== "undefined"
  ) {
    next();
  } else {
    if (isMobile()) {
      next({ name: "signin-mobile" });
    } else {
      next({ name: "signin-desktop" });
    }
  }
};

function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

//url를 통한 이동 시, Router에 걸러져서 html 상, <router-view/>에 component가 들어가짐
const router = new VueRouter({
  // 기본은 hash모드 이나, 일반 웹과 같이 사용하기 위해, history 모드로 설정
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Init",
      component: () => import("@/layouts/Init.vue"),
      beforeEnter: hasToken(),
    },
    {
      path: "/mobile",
      name: "mobile",
      components: {
        default: () => import("@/layouts/mobile/home/Home.vue"),
      },
      children: [
        {
          path: "service-status",
          name: "service-status",
          meta: { title: "운행관제" },
          component: () =>
            import(
              "@/layouts/mobile/home/contents/service-status/ServiceStatus.vue"
            ),
        },
        {
          path: "long-term-unused",
          name: "long-term-unused",
          meta: { title: "장기미운행" },
          component: () =>
            import(
              "@/layouts/mobile/home/contents/long-term-unused/LongTermUnused.vue"
            ),
        },
        {
          path: "etas-submission-status",
          name: "etas-submission-status",
          meta: { title: "eTAS 제출현황" },
          component: () =>
            import(
              "@/layouts/mobile/home/contents/etas-submission-status/EtasSubmissionStatus.vue"
            ),
        },
        {
          path: "car-tel-list",
          name: "car-tel-list",
          meta: { title: "차량회선 정보" },
          component: () =>
            import(
              "@/layouts/mobile/home/contents/car-tel-list/CarTelList.vue"
            ),
        },
        {
          path: "car-alc-status",
          name: "car-alc-status",
          meta: { title: "배차 현황" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcStatus.vue"),
        },
        {
          path: "car-alc",
          name: "car-alc",
          meta: { title: "배차 신청" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcList.vue"),
        },
        {
          path: "car-alc-range-time",
          name: "car-alc-range-time",
          meta: { title: "이용 시간" },
          component: () =>
            import(
              "@/layouts/mobile/home/contents/car-alc/CarAlcRangeTime.vue"
            ),
        },
        {
          path: "car-alc-step-1",
          name: "car-alc-step-1",
          meta: { title: "배차 신청" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcStep1.vue"),
        },
        {
          path: "car-alc-not-use",
          name: "car-alc-not-use",
          meta: { title: "배차 없음" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcNotUse.vue"),
        },
        {
          path: "car-alc-step-2",
          name: "car-alc-step-2",
          meta: { title: "사진 촬영" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcStep2.vue"),
        },
        {
          path: "car-alc-step-3",
          name: "car-alc-step-3",
          meta: { title: "사진 촬영" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcStep3.vue"),
        },
        {
          path: "car-alc-step-4",
          name: "car-alc-step-4",
          meta: { title: "입고처리" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcStep4.vue"),
        },
        {
          path: "car-alc-use",
          name: "car-alc-use",
          meta: { title: "사진 촬영" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcUse.vue"),
        },
        {
          path: "car-alc-parkl",
          name: "car-alc-parkl",
          meta: { title: "주차장위치" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcParkl.vue"),
        },
        {
          path: "car-alc-location",
          name: "car-alc-location",
          meta: { title: "현재 위치" },
          component: () =>
              import("@/layouts/mobile/home/contents/car-alc/CarAlcLocation.vue"),
        },
        {
          path: "car-alc-dtls",
          name: "car-alc-dtls",
          meta: { title: "배차내역" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcDtls.vue"),
        },
        {
          path: "etas-history",
          name: "etas-history",
          meta: { title: "eTAS 제출 내역" },
          component: () => import("@/layouts/mobile/home/contents/etas-submission-status/EtasHistory.vue"),
        },
        {
          path: "car-alc-rcd",
          name: "car-alc-rcd",
          meta: { title: "관리자 원격개폐" },
          component: () =>
            import("@/layouts/mobile/home/contents/car-alc/CarAlcRcd.vue"),
        },
        {
          path: "mypage",
          name: "",
          meta: { title: "마이페이지" },
          components: {
            default: () =>
              import("@/layouts/mobile/home/contents/mypage/Mypage.vue"),
          },
          children: [
            {
              path: "mypage-update",
              name: "mypage-update",
              meta: { title: "개인정보수정" },
              component: () =>
                import(
                  "@/layouts/mobile/home/contents/mypage/mypage-update/MypageUpdate.vue"
                ),
            },
            {
              path: "mypage-list",
              name: "mypage-list",
              meta: { title: "마이페이지" },
              component: () =>
                import(
                  "@/layouts/mobile/home/contents/mypage/mypage-list/MypageList.vue"
                ),
            },
          ],
        },
      ],
      // beforeEnter: hasToken(),
    },
    {
      path: "/home",
      name: "desktop",
      components: {
        default: () => import("@/layouts/desktop/home/Home.vue"),
      },
      children: [
        {
          path: "/map",
          name: "map",
          component: () =>
            import("@/layouts/desktop/home/contents/map/Map.vue"),
          //운행 이력 화면,
          meta: {
            hideFooter: true,
            useFullContent: true,
          },
        }, //지도 화면
        {
          path: "/driving-history",
          name: "driving-history",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/analysis/DrivingHistory.vue"
            ),
        }, //운행 이력 화면
        {
          path: "/driving-route",
          name: "driving-route",
          component: () =>
            import("@/layouts/desktop/home/contents/analysis/DrivingRoute.vue"),
        }, //운행 경로 화면
        {
          path: "/isgByCar",
          name: "isgByCar",
          component: () =>
            import("@/layouts/desktop/home/contents/isg/IsgByCar.vue"),
        }, //차량별 ISG 분석 화면
        {
          path: "/isgByDay",
          name: "isgByDay",
          component: () =>
            import("@/layouts/desktop/home/contents/isg/IsgByDay.vue"),
        }, //일별 ISG 분석 화면
        {
          path: "/isgByMonth",
          name: "isgByMonth",
          component: () =>
            import("@/layouts/desktop/home/contents/isg/IsgByMonth.vue"),
        }, //월별 ISG 분석 화면
        {
          path: "/isgByRealTime",
          name: "isgByRealTime",
          component: () =>
            import("@/layouts/desktop/home/contents/isg/IsgByRealTime.vue"),
        }, //실시간 ISG 분석 화면
        {
          path: "/break-time",
          name: "break-time",
          component: () =>
            import("@/layouts/desktop/home/contents/analysis/BreakTime.vue"),
        }, //휴게 시간 화면
        {
          path: "/record-position",
          name: "record-position",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/analysis/RecordPosition.vue"
            ),
        }, //거점 이력 화면
        {
          path: "/record-book",
          name: "record-book",
          component: () =>
            import("@/layouts/desktop/home/contents/analysis/RecordBook.vue"),
        }, //운행기록부 화면
        {
          path: "/drive-habit",
          name: "drive-habit",
          component: () =>
            import("@/layouts/desktop/home/contents/analysis/DriveHabit.vue"),
        }, //운전 습관 화면
        {
          path: "/driver-habit",
          name: "driver-habit",
          component: () =>
            import("@/layouts/desktop/home/contents/analysis/DriverHabit.vue"),
        }, //운전 습관 화면 - 운전자별
        {
          path: "/etas-sent",
          name: "etas-sent",
          component: () =>
            import("@/layouts/desktop/home/contents/etas-sent/EtasSent.vue"),
        }, //ETAS 제출 화면
        {
          path: "/etas-sent-download",
          name: "etas-sent-download",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/etas-sent/EtasSentDownload.vue"
            ),
        }, //ETAS 제출 다운로드 화면
        {
          path: "/etas/history",
          name: "etasHistoryList",
          component: () =>
              import("@/layouts/desktop/home/contents/etas-sent/EtasHistoryList"),
        },
        {
          path: "/repair/list/:page?",
          name: "repairList",
          component: () =>
            import("@/layouts/desktop/home/contents/repair/RepairList.vue"),
        }, //정비 관리 화면
        {
          path: "/repair/repairView",
          name: "repairView",
          component: () =>
              import("@/layouts/desktop/home/contents/repair/RepairView.vue"),
        }, //정비 상세 화면
        {
          path: "/repair/add",
          name: "repairAdd",
          component: () =>
            import("@/layouts/desktop/home/contents/repair/RepairAdd.vue"),
        }, //정비 추가 화면
        {
          path: "/repair/item/list/:page?",
          name: "repairItemList",
          component: () =>
            import("@/layouts/desktop/home/contents/repair/RepairItemList.vue"),
        }, //수리 품목 관리 화면
        {
          path: "/parking/list/:page?",
          name: "parkingList",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/ParkingList.vue"
            ),
        }, //주차장 관리 화면
        // {
        //   path: "/parking",
        //   name: "parking",
        //   component: () =>
        //     import(
        //       "@/layouts/desktop/home/contents/service-config/Parking.vue"
        //     ),
        // }, //주차장 관리 화면
        {
          path: "/parking/detail/:parkingId?",
          name: "parkingDetail",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/ParkingDetail.vue"
            ),
        }, // 주차장 상세 화면,
        {
          path: "/parking/add",
          name: "parkingAdd",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/ParkingAdd.vue"
            ),
        }, // 주차장 추가/수정{
        {
          path: "/parking/update/:parklSeq?",
          name: "parkingUpdate",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/ParkingUpdate.vue"
            ),
        }, // 주차장 추가/수정{
        {
          path: "/company",
          name: "company",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/Company.vue"
            ),
        }, //회사 관리 화면
        {
          path: "/department",
          name: "department",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/Department.vue"
            ),
        }, //부서 관리 화면
        {
          path: "/driver",
          name: "driver",
          component: () =>
            import("@/layouts/desktop/home/contents/service-config/Driver.vue"),
        }, //운전자 관리 화면
        {
          path: "/position",
          name: "position",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/Position.vue"
            ),
        }, //거점 관리 화면
        {
          path: "/car",
          name: "car",
          component: () =>
            import("@/layouts/desktop/home/contents/service-config/Car.vue"),
        }, //차량 관리 화면
        {
          path: "/member",
          name: "member",
          component: () =>
            import("@/layouts/desktop/home/contents/service-config/Member.vue"),
        }, //사용자 관리 화면
        {
          path: "/question",
          name: "question",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/Question.vue"
            ),
        }, //문의 게시판 화면
        {
          path: "/notice",
          name: "notice",
          component: () =>
            import("@/layouts/desktop/home/contents/service-config/Notice.vue"),
        }, //공지사항 화면
        {
          path: "/notice/noticeDetail/:noticeId",
          name: "noticeDetail",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/NoticeDetail.vue"
            ),
        }, // 공지사항 상세 화면
        {
          path: "/faq",
          name: "faq",
          component: () =>
            import("@/layouts/desktop/home/contents/service-config/FAQ.vue"),
        }, // FAQ 화면
        {
          path: "/faq/faqDetail/:faqId",
          name: "faqDetail",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/FaqDetail.vue"
            ),
        }, // FAQ 상세 화면
        {
          path: "/qna",
          name: "qna",
          component: () =>
            import("@/layouts/desktop/home/contents/service-config/QNA.vue"),
        }, // 문의 게시판 화면
        {
          path: "/qna/qnaDetail/:qnaId",
          name: "qnaDetail",
          component: () =>
            import(
              "@/layouts/desktop/home/contents/service-config/QnaDetail.vue"
            ),
        }, // 문의게시판 상세 화면
        {
          path: "/qna/qnaAdd/:qnaId?",
          name: "qnaAdd",
          component: () =>
            import("@/layouts/desktop/home/contents/service-config/QnaAdd.vue"),
        }, // 문의 게시판  추가/수정{

        /****************************************************
         * 배차관리
         **************************************************** */
        // 배차메인
        {
          path: "/caralc/main",
          name: "CaMainList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaMainList.vue"),
        },
        // 배차요청
        {
          path: "/caralc/req",
          name: "CaReqList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaReqList.vue"),
        },
        // 배차요청 상세
        {
          path: "/caralc/reqView",
          name: "CaReqView",
          component: () =>
              import("@/layouts/desktop/home/contents/caralc/CaReqView.vue")
        },
        // 입고점검내역
        {
          path: "/caralc/check",
          name: "CaCheckList",
          component: () => import("@/layouts/desktop/home/contents/caralc/CaCheckList.vue")
        },
        // 배차완료
        {
          path: "/caralc/rsed",
          name: "CaRsedList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaRsedList.vue"),
        },
        // 배차완료 상세
        {
          path: "/caralc/rsedview",
          name: "CaRsedView",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaRsedView.vue"),
          props: true,
        },
        // 사용완료 상세
        {
          path: "/caralc/comptview",
          name: "CaComptView",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaComptView.vue"),
          props: true,
        },
        // 배차불허 상세
        {
          path: "/caralc/nnpmsnview",
          name: "CaNnpmsnView",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaNnpmsnView.vue"),
          props: true,
        },
        // 사용중
        {
          path: "/caralc/use",
          name: "CaUseList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaUseList.vue"),
        },
        // 입고지연
        {
          path: "/caralc/whny",
          name: "CaWhnyList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaWhnyList.vue"),
        },
        // 배차취소
        {
          path: "/caralc/cancel",
          name: "CaCancelList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaCancelList.vue"),
        },
        // 배차취소 상세
        {
          path: "/caralc/cancelView",
          name: "CaCancelView",
          component: () =>
              import("@/layouts/desktop/home/contents/caralc/CaCancelView.vue")
        },
        // 배차내역
        {
          path: "/caralc/dtls",
          name: "CaDtlsList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaDtlsList.vue"),
        },
        // 운행일지
        {
          path: "/caralc/daily",
          name: "CaDailyList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaDailyList.vue"),
        },
        // 관리자-원격개폐
        {
          path: "/caralc/rcd",
          name: "CaRcdList",
          component: () =>
            import("@/layouts/desktop/home/contents/caralc/CaRcdList.vue"),
        },
      ],
      // beforeEnter: hasToken(),
    },
    // {
    //   path: "",
    //   redirect: "/",
    // },
    {
      path: "/signin-mobile",
      name: "signin-mobile",
      component: () => import("@/layouts/mobile/sign/Signin.vue"),
    },
    {
      path: "/signin-desktop",
      name: "signin-desktop",
      component: () => import("@/layouts/desktop/sign/Signin.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("@/layouts/mobile/PrivacyPolicy.vue"),
    },
    {
      // router에 등록되지않은 요청 시, 404페이지 전환
      path: "*",
      name: "404",
      component: () => import("@/layouts/mobile/error/Error404.vue"),
    },
    {
      // router에 등록되지않은 요청 시, 404페이지 전환
      path: "/500",
      name: "500",
      component: () => import("@/layouts/mobile/error/Error500.vue"),
    },
    {
      // router에 등록되지않은 요청 시, 404페이지 전환
      path: "/offline",
      name: "Offline",
      component: () => import("@/layouts/mobile/error/Offline.vue"),
    },
    // 운전자 연락처 QR
    {
      path: "/caralc/drvrTel/:carNo",
      name: "CaDrvrTel",
      component: () =>
        import(
          "@/layouts/desktop/home/contents/caralc/CaDrvrTel.vue"
        ),
    },
  ],
});

export default router
