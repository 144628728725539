<template>
  <v-app id="inspire">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
  }),
  created() {
  }
};
</script>
<style>
/* .theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #f5c17d70 !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #a17b4970 !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: #a17b49c2 !important;
}
.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: #ffd296d2 !important;
} */
html {
  overflow: hidden;
}
body {
  -ms-overflow-style: none;
  overflow: hidden;
}
.v-messages__message {
    line-height: 16px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0px !important;
}

.v-data-table td {
  /* font-size: 14px !important; */
}
.v-treeview--dense .v-treeview-node__root {
  min-height: 32px !important;
}

.v-treeview-node__level {
  width: 24px !important;
}

.v-text-field input {
  font-size: 14px !important;
}

.v-input .v-label {
  font-size: 14px !important;
}
.v-data-table-header {
    background-color: #EBEBEB;
 }
 
.tree-node-inner {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.draggable-placeholder-inner {
  border: 1px dashed #0088f8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}
.theme--light.v-text-field--solo-inverted.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background: #f7f8fa !important;
  border: 1px solid #d5d6da !important;
}
.theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  .v-label,
.theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  input {
  color: black !important;
}
.theme--dark.v-select .v-select__selections,
.theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused
  .v-select__selections {
  font-size: 14px;
  color: black !important;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  font-size: 10px;
  /* border-style: none !important; */
}
</style>
