import axios from "axios";
import { ApiHost } from "../constants";

export default {
  state: {
    isgDayList: [],
    isgTripList: [],
    isgEventList: [],
    isgSummaryList: [],
    isgMonthList: []
  },
  getters: {
    getIsgDayList: (state) => {
      return state.isgDayList;
    },
    getIsgTripList: (state) => {
      return state.isgTripList;
    },
    getIsgEventList: (state) => {
      return state.isgEventList;
    },
    getIsgSummaryList: (state) => {
      return state.isgSummaryList;
    },
    getIsgMonthList: (state) => {
      return state.isgMonthList;
    },
  },
  mutations: {
    setIsgTripList: (state, payload) => {
      state.isgTripList = payload;
    },
    setIsgDayList: (state, payload) => {
      state.isgDayList = payload;
    },
    setIsgEventList: (state, payload) => {
      state.isgEventList = payload;
    },
    setIsgSummaryList: (state, payload) => {
      state.isgSummaryList = payload;
    },
    setIsgMonthList: (state, payload) => {
      state.isgMonthList = payload;
    },
  },
  actions: {
    async setIsgTripList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.dptCd !== undefined) params.append("dptCd", payload.dptCd);
      if (payload.carNo !== undefined) params.append("carNo", payload.carNo);
      if (payload.strTm !== undefined) params.append("strTm", payload.strTm);
      if (payload.endTm !== undefined) params.append("endTm", payload.endTm);
      var isgTripList = await axios.get(
        `${ApiHost}/moove/api/isg/trip/` +
          payload.bizNo +
          `/` +
          payload.strDt +
          `/` +
          payload.endDt,
        { params: params }
      );
      commit("setIsgTripList", isgTripList.data);
    },
    async setIsgDayList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.dptCd !== undefined) params.append("dptCd", payload.dptCd);
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.strTm !== undefined) params.append("strTm", payload.strTm);
      if (payload.endTm !== undefined) params.append("endTm", payload.endTm);
      var isgDayList = await axios.get(
        `${ApiHost}/moove/api/isg/day/` +
          payload.bizNo +
          `/` +
          payload.strDt +
          `/` +
          payload.endDt,
        { params: params }
      );
      commit("setIsgDayList", isgDayList.data);
    },
    async setIsgMonthList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.dptCd !== undefined) params.append("dptCd", payload.dptCd);
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.strTm !== undefined) params.append("strTm", payload.strTm);
      if (payload.endTm !== undefined) params.append("endTm", payload.endTm);
      var isgMonthList = await axios.get(
        `${ApiHost}/moove/api/isg/mth/` +
          payload.bizNo +
          `/` +
          payload.strDt +
          `/` +
          payload.endDt,
        { params: params }
      );
      commit("setIsgMonthList", isgMonthList.data);
    },
    async setIsgEventList({ commit }, payload) {
      const params = new URLSearchParams();
      // if (payload.dptCd !== undefined) params.append("dptCd", payload.dptCd);
      if (payload.carNo !== undefined) params.append("carNo", payload.carNo);
      // if (payload.strTm !== undefined) params.append("strTm", payload.strTm);
      // if (payload.endTm !== undefined) params.append("endTm", payload.endTm);
      // if (payload.engOn !== undefined) params.append("engOn", payload.engOn);
      // if (payload.engOff !== undefined) params.append("engOff", payload.engOff);
      // if (payload.isgOn !== undefined) params.append("isgOn", payload.isgOn);
      // if (payload.isgOff !== undefined) params.append("isgOff", payload.isgOff);
      // if (payload.pOut !== undefined) params.append("pOut", payload.pOut);
      // if (payload.pIn !== undefined) params.append("pIn", payload.pIn);
      // if (payload.drvStr !== undefined) params.append("drvStr", payload.drvStr);
      // if (payload.drvStp !== undefined) params.append("drvStp", payload.drvStp);
      // if (payload.keyOn !== undefined) params.append("keyOn", payload.keyOn);
      // if (payload.drv !== undefined) params.append("drv", payload.drv);
      // if (payload.idle !== undefined) params.append("idle", payload.idle);
      var isgEventList = await axios.get(
        `${ApiHost}/moove/api/isg/event/` +
          payload.carNo +
          `/` +
          payload.strDt +
          `/` +
          payload.endDt +
          "?engOn=engOn&engOff=engOff&isgOn=isgOn&isgOff&isgOff&drvStr=drvStr&drvStp=drvStp",
        { params: params }
      );
      commit("setIsgEventList", isgEventList.data);
    },
    async setIsgSummaryList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var isgSummaryList = await axios.get(
        `${ApiHost}/moove/api/isg/sum/` +
          payload.bizNo +
          `/` +
          payload.strDt +
          `/` +
          payload.endDt,
        { params: params }
      );
      commit("setIsgSummaryList", isgSummaryList.data);
    },
  },
};
