import axios from "axios";
import { ApiHost } from "../constants";

export default {
  state: {
    serverTime: 0,
    authList: [],
    mnuAuthLnkList: [],
    mnuList: [],
    menuMobile: [],
    authInfo: {},
    mbrDptLnkList: [],
    selectedMbrDptLnkList: [],
    selectedMbrDptPrntItems: [],
    selectedMbrDptItems: [],
  },

  getters: {
    getServerTime: (state) => {
      return state.serverTime;
    },
    getAuthList: (state) => {
      return state.authList;
    },
    getMnuAuthLnkList: (state) => {
      return state.mnuAuthLnkList;
    },
    getMnuList: (state) => {
      return state.mnuList;
    },
    getMenuMobile: (state) => {
      return state.menuMobile;
    },
    getAuthInfo: (state) => {
      return state.authInfo;
    },
    getMbrDptLnkList: (state) => {
      return state.mbrDptLnkList;
    },
    getSelectedMbrDptLnkList: (state) => {
      return state.selectedMbrDptLnkList;
    },
    getSelectedMbrDptPrntItems: (state) => {
      return state.selectedMbrDptPrntItems;
    },
    getSelectedMbrDptItems: (state) => {
      return state.selectedMbrDptItems;
    },
  },

  mutations: {
    setServerTime: (state, payload) => {
      state.serverTime = payload;
    },
    setAuthList: (state, payload) => {
      state.authList = payload;
    },
    setMnuAuthLnkList: (state, payload) => {
      state.mnuAuthLnkList = payload;
    },
    setMunList: (state, payload) => {
      state.mnuList = payload;
    },
    setMenuMobile: (state, payload) => {
      state.menuMobile = payload;
    },
    setAuthInfo: (state, payload) => {
      state.authInfo = payload;
    },
    setMbrDptLnkList: (state, payload) => {
      state.mbrDptLnkList = payload;
    },
    setSelectedMbrDptLnkList: (state, payload) => {
      state.selectedMbrDptLnkList = payload;
    },
    setSelectedMbrDptPrntItems: (state, payload) => {
      state.selectedMbrDptPrntItems = payload;
    },
    setSelectedMbrDptItems: (state, payload) => {
      state.selectedMbrDptItems = payload;
    },
  },

  actions: {
    setAuthList: ({ commit }) => {
      axios.get(`${ApiHost}/moove/api/auth`).then((res) => {
        if (res.data.length == 0) commit("setAuthList", []);
        else commit("setAuthList", res.data);
      });
    },
    setMnuList: ({ commit }) => {
      axios.get(`${ApiHost}/moove/api/menu`).then((res) => {
        if (res.data.length == 0) commit("setMunList", []);
        else commit("setMunList", res.data);
      });
    },
    async setMenuMobile({ commit }, payload) {
      var menu = await axios.get(`${ApiHost}/moove/api/menu/` + payload +
    		  `?mnuTp=02`);
      commit("setMenuMobile", menu.data[0].children);
    },
    // MnuAuthLnk 전체 삭제 후 저장
    async saveAfterDelMnuAuthLnk({ dispatch }, payloads) {
      await axios
        .delete(`${ApiHost}/moove/api/mnuauthlnk/` + payloads[0].authId)
        .then(() => {
          for (var i = 0; i < payloads.length; i++) {
            dispatch("saveMnuAuthLnk", payloads[i]);
          }
        });
    },
    async saveAuth({ dispatch }, payload) {
      await axios.post(`${ApiHost}/moove/api/auth`, payload);
      dispatch("setAuthList");
    },
    async updateAuth({ dispatch }, payload) {
      var body = {
        authCd: payload.authCd,
        authId: payload.authId,
        authTp: payload.authTp,
        authNm: payload.authNm,
        authDesc: payload.authDesc,
        creId: payload.creId,
        scp: payload.scp,
      };
      await axios.put(`${ApiHost}/moove/api/auth`, body);
      dispatch("setAuthList");
    },
    async deleteAuth({ dispatch }, payload) {
      await axios.delete(`${ApiHost}/moove/api/auth`, {
        data: {
          authId: payload.authId,
        },
        withCredentials: true,
      });
      dispatch("setAuthList");
    },
    async setMbrDptLnkList ({commit}, payload) {
      await axios.get(`${ApiHost}/moove/api/member/dpt?lgnId=${payload}`).then(v => {
        commit("setSelectedMbrDptLnkList", v.data);
      });
    },
    setSelectedMbrDptLnkList: ({ commit }, payload) => {
      commit("setSelectedMbrDptLnkList", payload);
    },
    async saveMbrDptLnk ({commit}, payload) {
      await axios.post(`${ApiHost}/moove/api/member/dpt/save`, payload);
    },
    setSelectedMbrDptPrntItems: ({commit}, payload) => {
      commit("setSelectedMbrDptPrntItems", payload);
    },
    setSelectedMbrDptItems: ({commit}, payload) => {
      commit("setSelectedMbrDptItems", payload);
    },
    clearSelectedMbrDpt: ({commit}) => {
      commit("setSelectedMbrDptPrntItems", []);
      commit("setSelectedMbrDptItems", []);
      commit("setSelectedMbrDptLnkList", []);
    },
    /**
     * 서버 시간 취득
     * @param payload before true = 10분전 시간, after true = 10분후 시간
     * @returns null or milliSeconds
     */
    async setServerTime({commit}, payload = {before: false, after: false}) {
      let url = `${ApiHost}/moove/api/auth/time`;
      let param = "?";
      let array = [];
      if (payload.before) array.push(`before=${payload.before}`);
      if (payload.after) array.push(`after=${payload.after}`);

      for (let i = 0; i < array.length; i++) {
        if (param.length === 1) {
          param = param.concat(array[i]);
        } else {
          param = param.concat(`&${array[i]}`);
        }
      }

      const result = (await axios.get(url.concat(param))).data;
      if (result.statusCode !== 200) {
        console.error(`getServerTime Error <<>> ${result.message}`);
      } else {
        commit("setServerTime", result.data);
      }
      return result.data;
    },
  },
};
