import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueAnalytics from "vue-analytics";
import VueDaumPostcode from "vue-daum-postcode" 
import VueParticlesBg from "particles-bg-vue";
import CKEditor from 'ckeditor4-vue';
import SmoothPicker from 'vue-smooth-picker'

import 'vue-smooth-picker/dist/css/style.css'
Vue.use(SmoothPicker)

import VueScrollPicker from "vue-scroll-picker"
import "vue-scroll-picker/dist/style.css"
Vue.use(VueScrollPicker)
// Vue.prototype.$vuetify = Object.assign(Vue.prototype.$vuetify, {
//   theme: {
//     primary: '#000000',
//     secondary: '#b0bec5'
//   },
//   options: {
//     customProperties: true
//   }
// })

Vue.use(CKEditor);
Vue.use(VueDaumPostcode); //다음 우편주소 Component 추가
Vue.use(VueParticlesBg); //백그라운드 Component 추가

// import "@/assets/css/moove_mobile.css"; // onion 디자인 추가
// import "@/assets/css/moove_desktop.css"; // onion 디자인 추가
// import "@/assets/css/basic_m.css"; // onion 디자인 추가
// import "@/assets/css/common.css"; //  onion 디자인 추가
// import "@/assets/css/datepicker_ko.css"; //  onion 디자인 추가
// import "@/assets/css/layout_m.css"; // onion 디자인 추가

Vue.config.productionTip = false;

Vue.use(VueAnalytics, { //Google Analytics Config
  id: "UA-153146782-1",
  router,
});

new Vue({
  router, // 라우터
  store,  // 스토어
  vuetify,// CSS 프레임워크
  render: (h) => h(App),
  beforeCreate() {
    if (localStorage.getItem("access_token") !== null) {
      this.$store.dispatch("setMember");
    }
  },
}).$mount("#app");
