import axios from "axios";
import { ApiHost } from "../constants";

export default {
  state: {
    qnaTypeList: [
      { name: "서비스", qnaType: "01" },
      { name: "단말", qnaType: "02" },
      { name: "청약", qnaType: "03" },
      { name: "기타", qnaType: "04" },
    ],
    attFileList: [],
    qna: {},
    qnaList: [],
    qAttFileList: [],
    aAttFileList: [],
  },

  getters: {
    getQna: (state) => {
      return state.qna;
    },
    getQnaList: (state) => {
      if (state.qnaList.length == 0) return state.qnaList;

      return state.qnaList.map(function(ele) {
        var qnaType = state.qnaTypeList.filter((n) => n.qnaType == ele.qnaType);

        var ele1 = {
          ...ele,
          qnaTypeNm: qnaType.map((c) => c.name),
        };
        return ele1;
      });
    },
    getAttFileList: (state) => {
      if (state.attFileList.length == 0) return state.attFileList;
      return state.attFileList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.fileId,
        };
        return ele1;
      });
    },
    getQAttFileList: (state) => {
      if (state.qAttFileList.length == 0) return state.qAttFileList;
      return state.qAttFileList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.fileId,
        };
        return ele1;
      });
    },
    getAAttFileList: (state) => {
      if (state.aAttFileList.length == 0) return state.aAttFileList;
      return state.aAttFileList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.fileId,
        };
        return ele1;
      });
    },
    getQnaTypeList: (state) => {
      return state.qnaTypeList;
    },
  },

  mutations: {
    setQna: (state, payload) => {
      var qnaType = state.qnaTypeList.filter(
        (n) => n.qnaType == payload.qnaType
      );
      state.qna = {
        ...payload,
        qnaTypeNm: qnaType.map((c) => c.name)[0],
      };
    },
    setQnaList: (state, payload) => {
      if (payload == "") payload = [];
      state.qnaList = payload;
    },
    setQnaTypeList: (state, payload) => {
      state.qnaTypeList = payload;
    },
    setAttFileList: (state, payload) => {
      state.attFileList = payload;
    },
    setQAttFileList: (state, payload) => {
      state.qAttFileList = payload;
    },
    setAAttFileList: (state, payload) => {
      state.aAttFileList = payload;
    },
  },

  actions: {
    async setQna ({ commit }, payload) {
      await axios.get(`${ApiHost}/moove/api/qna/` + payload.qnaId).then((res) => {
        commit("setQna", res.data);
      });
    },
    async setQnaList ({ commit }) {
      await axios.get(`${ApiHost}/moove/api/qna`).then((res) => {
        commit("setQnaList", res.data);
      });
    },
    clearQnaList({commit}) {
      commit("setQnaList", []);
    },
    async deleteQnaAttFile({ commit }, payload) {
      await axios.delete(`${ApiHost}/moove/api/attfile/` + payload.fileId);
      var attFileList = await axios.get(
        `${ApiHost}/moove/api/qna/attfile/` + payload.boardId + "/Q"
      );
      commit("setQAttFileList", attFileList.data);
    },
    async updateQna({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/qna`, payload);
      await axios
        .get(`${ApiHost}/moove/api/qna/` + payload.qnaId)
        .then((res) => {
          commit("setQna", res.data);
        });
    },
    async updateQnaList({ commit }, formData) {
      await axios
        .post(`${ApiHost}/moove/api/qna`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function() {})
        .catch(function() {});

      var qnaList = await axios.get(`${ApiHost}/moove/api/qna`);
      commit("setQnaList", qnaList.data);
    },
    async deleteQna({ commit }, qnaId) {
      await axios.delete(`${ApiHost}/moove/api/qna/` + qnaId);
      var qnaList = await axios.get(`${ApiHost}/moove/api/qna/`);
      commit("setQnaList", qnaList.data);
    },
    async loadQnaAttFileList({ commit }, payload) {
      var qAttFileList = await axios.get(
        `${ApiHost}/moove/api/qna/attfile/` + payload.qnaId + "/Q"
      );
      commit("setQAttFileList", qAttFileList.data);

      var aAttFileList = await axios.get(
        `${ApiHost}/moove/api/qna/attfile/` + payload.qnaId + "/A"
      );
      commit("setAAttFileList", aAttFileList.data);
    },
  },
};
