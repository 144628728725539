import axios from 'axios';
import {ApiHost} from '../constants'

export default {
  
  state: {
    groupsCdList: [],
    detailsCdList: [],
  },
  
  getters: {
    getGroupsCdList: state => {
      return state.groupsCdList;
    },
    getDetailsCdList: state => {
      return state.detailsCdList;
    },
  },

  mutations: {
    setGroupsCdList: (state, payload) => {
      state.groupsCdList = payload;
    },
    setDetailsCdList: (state, payload) => {
      state.detailsCdList = payload;
    },
  },

  actions: {
    setGroupsCdList: ({ commit }) => {
      axios.get(`${ApiHost}/moove/api/cd/groups`).then(res => {
        if(res.data.length==0)
          commit('setGroupsCdList', []);
        else
          commit('setGroupsCdList', res.data);
      });
    },
    setDetailsCdList: ({ commit }, payload) => {
      axios.get(`${ApiHost}/moove/api/cd/details/` + payload.cdId)
           .then(res => {
        let list = (res.data.length>0 ? res.data:[]) 
        commit('setDetailsCdList', list);
      });
    },
    async saveCd({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/cd`, payload);
      
      if(payload.cdTp == '01')
      {
        this.dispatch('cd/setGroupsCdList');
      }
      else
      {
        axios.get(`${ApiHost}/moove/api/cd/details/` + payload.prntCdId)
             .then(res => {
          let list = (res.data.length>0 ? res.data:[]) 
          commit('setDetailsCdList', list);
        });
      }
    },
    async deleteCd({ commit }, payload) {
      await axios.delete(
        `${ApiHost}/moove/api/cd/` + payload.cdId );

      if(payload.cdTp == '01')
      {
        this.dispatch('cd/setGroupsCdList');
      }
      else
      {
        axios.get(`${ApiHost}/moove/api/cd/details/` + payload.prntCdId)
             .then(res => {
          let list = (res.data.length>0 ? res.data:[]) 
          commit('setDetailsCdList', list);
        });
      }
    },
    async getCdList({commit}, payload) {
    	const result = await axios.get(
    			`${ApiHost}/moove/api/cd/details/gcd/` + payload.cd
    			);
    	return result.data;
    },
  }
}