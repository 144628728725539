import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#6E63C3",
        secondary: "#DCCDEF",
        background: "#F1F4F6",
        card: "#FFFFFF",
        interest: "#1C84EF",
        tableHeader: "#EBEBEB",
        bar: "#C4C4C4"
      },
      // dark:{
      //   primary: "#673ab7",
      //   secondary: "lightgray",
      //   accent: "#03a9f4",
      //   error: "#e91e63",
      //   warning: "#ffeb3b",
      //   info: "#3f51b5",
      //   success: "#4caf50"
      //   }
      
    },
    options: { customProperties: true }
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
});
