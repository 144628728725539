import axios from "axios";
import { ApiHost } from "../constants";

export default {
  state: {
	  carImg: {},
	  carListByDptCd: [],
  },

  getters: {
    getCarImg: state => {
      return state.carImg;
    },
    getCarListByDptCd: (state) => {
      return state.carListByDptCd;
    },
  },

  mutations: {
    setCarImg: (state, payload) => {
      state.carImg = payload;
    },
    setCarListByDptCd: (state, payload) => {
      state.carListByDptCd = payload;
    },
  },

  actions: {
	async setCarImg({ commit }, payload) {
	  var result = await axios.get(`${ApiHost}/moove/api/car/carimg/`+payload.carId);
	  return result.data;
    },

    async saveCarImg({ commit }, formData) {
      await axios.post(`${ApiHost}/moove/api/car/carimg`, formData, 
    		  {
    	  		headers: { 
    	  			"Content-Type": "multipart/form-data", 
    	  		},
    		  }
      )
      .then(function() {})
      .catch(function() {});
    },
    
    async deleteCarImg({commit}, payload) {
    	await axios.delete(`${ApiHost}/moove/api/car/carimg/`+payload.carId);
    },
    async setCarListByDptCd({ commit }, dptModel) {
      const params = new URLSearchParams();
      if (dptModel.dptCd != null) {
        params.append("dptCd", dptModel.dptCd);
      }
      var carListByDptCd = await axios.get(
        `${ApiHost}/moove/api/car/control/` + dptModel.bizNo,
        {
          params: params,
        }
      );
      commit("setCarListByDptCd", carListByDptCd.data);
    },
  },

};
