import axios from "axios";
import { ApiHost } from "../constants";
import {emptyCheck} from "@/utils/util";

export default {
  namespaces: true,
  state: {
    carAlcTimeRangeDialog: false,
    rsvtCalendarDialog: false,
    pageTitle: [{
      text: "배차관리",
      disabled: false,
      depth: 0
    }],
    startDayList: [],
    startDay: {
      value:
        String(new Date().getFullYear()) +
        "-" +
        String(new Date().getMonth() + 1).padStart(2, "0") +
        "-" +
        String(new Date().getDate()).padStart(2, "0"),
      str:
        String(new Date().getMonth() + 1).padStart(2, "0") +
        " / " +
        String(new Date().getDate()).padStart(2, "0") +
        " (" +
        ["일", "월", "화", "수", "목", "금", "토"][new Date().getDay()] +
        ")",
    },
    startHour:
      (Math.floor(new Date().getMinutes() / 10) + 1) * 10 === 60
        ? (new Date().getHours() + 1).toString()
        : new Date().getHours().toString(),
    startMinute:
      (Math.floor(new Date().getMinutes() / 10) + 1) * 10 === 60
        ? "00"
        : ((Math.floor(new Date().getMinutes() / 10) + 1) * 10).toString(),
    endDayList: [],
    endDay: {
      value: (function() {
        let now = new Date();
        let tomarrow = new Date(now.setDate(now.getDate() + 1));
        return (
          String(tomarrow.getFullYear()) +
          "-" +
          String(tomarrow.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(tomarrow.getDate()).padStart(2, "0")
        );
      })(),
      str: (function() {
        let now = new Date();
        let tomarrow = new Date(now.setDate(now.getDate() + 1));
        return (
          String(tomarrow.getMonth() + 1).padStart(2, "0") +
          " / " +
          String(tomarrow.getDate()).padStart(2, "0") +
          " (" +
          ["일", "월", "화", "수", "목", "금", "토"][tomarrow.getDay()] +
          ")"
        );
      })(),
    },
    endHour:
      (Math.floor(new Date().getMinutes() / 10) + 1) * 10 === 60
        ? (new Date().getHours() + 1).toString()
        : new Date().getHours().toString(),
    endMinute:
      (Math.floor(new Date().getMinutes() / 10) + 1) * 10 === 60
        ? "00"
        : ((Math.floor(new Date().getMinutes() / 10) + 1) * 10).toString(),
    carAlcList: [],
    carReqList: [],
    carCancelList: [],
    carAlcUsefulList: [],
    carAlcUserList: [],
    carAlcRcdCarList: [],
    carAlcAllList: [],
    carAlcCheckList: [],
    carLastWh: {},
    carAlcUse: {},
    carAlcUseList: [],
    carAlcWhnyList: [],
    carAlcDtlsList: [],
    caHistList: [],
    carHistByRsvtList: [],
    rsvtStateList: [],
    oilCdList: [],
    caWrhousng: [],
    caParking: {},
    carAlcMDtlsList: [],
    doorLock: "",
    currentStatus: "",
    dailyCarList: [],
    dailyTrpList: [],
    carUseBeginTime: "",
    carUseEndTime: "",
    carUsingList: [],
    carListByCarNo: [],
    car: {},
    caDriverMbr: {},
  },

  getters: {
    getCarAlcTimeRangeDialog: (state) => {
      return state.carAlcTimeRangeDialog;
    },
    getCarAlcAllList: (state) => {
      return state.carAlcAllList;
    },
    getPageTitle: (state) => {
      return state.pageTitle;
    },
    getStartDayList: (state) => {
      return state.startDayList;
    },
    getStartDay: (state) => {
      return state.startDay;
    },
    getStartHour: (state) => {
      return state.startHour;
    },
    getStartMinute: (state) => {
      return state.startMinute;
    },
    getEndDayList: (state) => {
      return state.endDayList;
    },
    getEndDay: (state) => {
      return state.endDay;
    },
    getEndHour: (state) => {
      return state.endHour;
    },
    getEndMinute: (state) => {
      return state.endMinute;
    },
    getCarAlcList: (state) => {
      return state.carAlcList;
    },
    getCarReqList: (state) => {
      return state.carReqList;
    },
    getCarCancelList: (state) => {
      return state.carCancelList;
    },
    getCarAlcUsefulList: (state) => {
      return state.carAlcUsefulList;
    },
    getCarAlcUserList: (state) => {
      return state.carAlcUserList;
    },
    getCarAlcRcdCarList: (state) => {
      return state.carAlcRcdCarList;
    },
    getCarAlcUse: (state) => {
      return state.carAlcUse;
    },
    getCarAlcUseList: (state) => {
      return state.carAlcUseList;
    },
    getCarLastWh: (state) => {
      return state.carLastWh;
    },
    getCarAlcWhnyList: (state) => {
      return state.carAlcWhnyList;
    },
    getCarAlcDtlsList: (state) => {
      return state.carAlcDtlsList;
    },
    getCarHistByRsvtState: (state) => {
      return state.carHistByRsvtList;
    },
    getCaHistList: (state) => {
      return state.caHistList;
    },
    getRsvtStateList: (state) => {
      return state.rsvtStateList;
    },
    getOilCdList: (state) => {
      return state.oilCdList;
    },
    getCaWrhousng: (state) => {
      return state.caWrhousng;
    },
    getCaParking: (state) => {
      return state.caParking;
    },
    getCarAlcMDtlsList: (state) => {
      return state.carAlcMDtlsList;
    },
    getCurrentStatus: (state) => {
      return state.currentStatus;
    },
    getDailyCarList: (state) => {
      return state.dailyCarList;
    },
    getDailyTrpList: (state) => {
      return state.dailyTrpList;
    },
    getCarUseBeginTime: (state) => {
      return state.carUseBeginTime;
    },
    getCarUseEndTime: (state) => {
      return state.carUseEndTime;
    },
    getCarUsingList: (state) => {
      return state.carUsingList;
    },
    getRsvtCalendarDialog: (state) => {
      return state.rsvtCalendarDialog;
    },
    getCarAlcCheckList: (state) => {
      return state.carAlcCheckList;
    },
    getCarListByCarNo: (state) => {
      return state.carListByCarNo;
    },
    getCar: (state) => {
      return state.car;
    },
    getCaDriverMbr: (state) => {
      return state.caDriverMbr;
    },
  },

  mutations: {
    setCarAlcTimeRangeDialog: (state, payload) => {
      state.carAlcTimeRangeDialog = payload;
    },
    setCarAlcAllList: (state, payload) => {
      state.carAlcAllList = payload;
    },
    setPageTitle: (state, payload) => {
      const titleLength = state.pageTitle.length;

      if (titleLength > payload.depth + 1) {
        // Shallower depth
        for (let i = 0; i < titleLength - payload.depth; i++) {
          state.pageTitle.pop();
        }
      } else if (titleLength === payload.depth + 1) {
        // Same depth
        state.pageTitle.pop();
      }

      state.pageTitle.push(payload);
    },
    setStartDayList: (state, payload) => {
      state.startDayList = payload;
    },
    setStartDay: (state, payload) => {
      state.startDay = payload;
    },
    setStartHour: (state, payload) => {
      state.startHour = payload;
    },
    setStartMinute: (state, payload) => {
      state.startMinute = payload;
    },
    setEndDayList: (state, payload) => {
      state.endDayList = payload;
    },
    setEndDay: (state, payload) => {
      state.endDay = payload;
    },
    setEndHour: (state, payload) => {
      state.endHour = payload;
    },
    setEndMinute: (state, payload) => {
      state.endMinute = payload;
    },
    setDoorUnLock: () => {
      // state.doorLock=payload;
      // state.endMinute = payload;
    },
    setDoorLock: (state, payload) => {
      state.doorLock = payload;
      // state.endMinute = payload;
    },
    setHazardOn: () => {
      // state.endMinute = payload;
    },
    setCurrentStatus: (state, payload) => {
      state.currentStatus = payload;
    },
    setCarAlcList: (state, payload) => {
      state.carAlcList = payload;
    },
    setCarReqList: (state, payload) => {
      state.carReqList = payload;
    },
    setCarCancelList: (state, payload) => {
      state.carCancelList = payload;
    },
    setCarAlcUsefulList: (state, payload) => {
      state.carAlcUsefulList = payload;
    },
    setCarAlcUserList: (state, payload) => {
      state.carAlcUserList = payload;
    },
    setCarAlcRcdCarList: (state, payload) => {
      state.carAlcRcdCarList = payload;
    },
    setCarAlcUse: (state, payload) => {
      state.carAlcUse = payload;
    },
    setCarAlcUseList: (state, payload) => {
      state.carAlcUseList = payload;
    },
    setCarAlcWhnyList: (state, payload) => {
      state.carAlcWhnyList = payload;
    },
    setCarAlcDtlsList: (state, payload) => {
      state.carAlcDtlsList = payload;
    },
    setCarHistByRsvtState: (state, payload) => {
      state.carHistByRsvtList = payload;
    },
    setCaHistList: (state, payload) => {
      state.caHistList = payload;
    },
    setRsvtStateList: (state, payload) => {
      state.rsvtStateList = payload;
    },
    setOilCdList: (state, payload) => {
      state.oilCdList = payload;
    },
    setCaWrhousng: (state, payload) => {
      state.caWrhousng = payload;
    },
    setCarLastWh: (state, payload) => {
      state.carLastWh = payload;
    },
    setCaParking: (state, payload) => {
      state.caParking = payload;
    },
    setCarAlcMDtlsList: (state, payload) => {
      state.carAlcMDtlsList = payload;
    },
    setDailyCarList: (state, payload) => {
      state.dailyCarList = payload;
    },
    setDailyTrpList: (state, payload) => {
      state.dailyTrpList = payload;
    },
    setCarUseBeginTime: (state, payload) => {
      state.carUseBeginTime = payload;
    },
    setCarUseEndTime: (state, payload) => {
      state.carUseEndTime = payload;
    },
    setCarUsingList: (state, payload) => {
      state.carUsingList = payload;
    },
    setRsvtCalendarDialog: (state, payload) => {
      state.rsvtCalendarDialog = payload;
    },
    setCarAlcCheckList: (state, payload) => {
      state.carAlcCheckList = payload;
    },
    setCarListByCarNo: (state, payload) => {
      state.carListByCarNo = payload;
    },
    setCar: (state, payload) => {
      state.car = payload;
    },
    setCaDriverMbr: (state, payload) => {
      state.caDriverMbr = payload;
    },
  },

  actions: {
    setCarAlcTimeRangeDialog: ({ commit }, payload) => {
      commit("setCarAlcTimeRangeDialog", payload);
    },
    setStartDayList: ({ commit }, payload) => {
      commit("setStartDayList", payload);
    },
    setStartDay: ({ commit }, payload) => {
      commit("setStartDay", payload);
    },
    setStartHour: ({ commit }, payload) => {
      commit("setStartHour", payload);
    },
    setStartMinute: ({ commit }, payload) => {
      commit("setStartMinute", payload);
    },
    setEndDayList: ({ commit }, payload) => {
      commit("setEndDayList", payload);
    },
    setEndDay: ({ commit }, payload) => {
      commit("setEndDay", payload);
    },
    setEndHour: ({ commit }, payload) => {
      commit("setEndHour", payload);
    },
    setEndMinute: ({ commit }, payload) => {
      commit("setEndMinute", payload);
    },
    async setCarAlcList({ commit }, payload) {
      let param = `?rsvtState=${payload.rsvtState}`;
      if (!emptyCheck(payload.dptCd)) param = param.concat(`&dptCd=${payload.dptCd}`);
      if (!emptyCheck(payload.yyyyMM)) param = param.concat(`&yyyyMM=${payload.yyyyMM}`);
      let carAlcList = await axios.get(
        `${ApiHost}/moove/api/caralc/list/${payload.bizNo}` + param
      );
      commit("setCarAlcList", carAlcList.data);
    },
    async setCarAlcAllList({commit}, payload) {
      let param = `?bgnDt=${payload.bgnDt}&endDt=${payload.endDt}`;
      if (!emptyCheck(payload.lgnId)) {
        param = param.concat(`&lgnId=${payload.lgnId}`);
      }
      const result = await axios.get(
          `${ApiHost}/moove/api/caralc/list/all/${payload.bizNo}` + param
      );
      commit("setCarAlcAllList", result.data);
    },
    clearCarAlcAllList({commit}) {
      commit("setCarAlcAllList", []);
    },
    async setCarRsedList({commit}, payload) {
      let param = `?rsvtState=${payload.rsvtState}`;
      if (!emptyCheck(payload.dptCd)) param = param.concat(`&dptCd=${payload.dptCd}`);
      if (!emptyCheck(payload.yyyyMM)) param = param.concat(`&yyyyMM=${payload.yyyyMM}`);
      let carAlcList = await axios.get(
          `${ApiHost}/moove/api/caralc/rsed/list/${payload.bizNo}` + param
      );
      commit("setCarAlcList", carAlcList.data);
    },
    async setCarReqList({commit}, payload) {
      let param = `?rsvtState=${payload.rsvtState}`;
      if (!emptyCheck(payload.dptCd)) param = param.concat(`&dptCd=${payload.dptCd}`);
      let carReqList = await axios.get(
          `${ApiHost}/moove/api/caralc/list/${payload.bizNo}` + param
      );
      commit("setCarReqList", carReqList.data);
    },
    async setCarCancelList({commit}, payload) {
      let param = `?rsvtState=${payload.rsvtState}`;
      if (!emptyCheck(payload.dptCd)) param = param.concat(`dptCd=${payload.dptCd}`);
      let carCancelList = await axios.get(
          `${ApiHost}/moove/api/caralc/list/${payload.bizNo}` + param
      );
      commit("setCarCancelList", carCancelList.data);
    },
    async setCarAlcUsefulList({ commit }, payload) {
      let url = `${ApiHost}/moove/api/caralc/useful/` +
          payload.bizNo +
          "?bgnDt=" +
          payload.bgnDt +
          "&endDt=" +
          payload.endDt;

      if (!emptyCheck(payload.lgnId)) url = url.concat(`&lgnId=${payload.lgnId}`);

      let carAlcList = await axios.get(url);
      commit("setCarAlcUsefulList", carAlcList.data);
    },
    async setCarAlcUserList({commit}, payload) {
      const carAlcUserList = (await axios.get(`${ApiHost}/moove/api/caralc/user/${payload}`)).data;
      commit("setCarAlcUserList", carAlcUserList);
    },
    async setCarAlcRcdCarList({ commit }, payload) {
      let carAlcRcdCarList = await axios.get(
        `${ApiHost}/moove/api/caralc/rcd/` +
          payload.bizNo +
          `?&dptCd=` +
          payload.dptCd
      );
      commit("setCarAlcRcdCarList", carAlcRcdCarList.data);
    },
    async setDoorUnLock({ commit }, payload) {
      let doorLock = await axios.post(
        `${ApiHost}/moove/api/otoplug/command/door/unlock`,
        { carNo: payload }
      );
      commit("setDoorUnLock", doorLock);
    },
    async setDoorLock({ commit }, payload) {
      let doorLock = await axios.post(
        `${ApiHost}/moove/api/otoplug/command/door/lock`,
        { carNo: payload }
      );
      commit("setDoorLock", doorLock);
    },
    async setHazardOn({ commit }, payload) {
      let doorLock = await axios.post(
        `${ApiHost}/moove/api/otoplug/command/hazard/on`,
        { carNo: payload }
      );
      commit("setHazardOn", doorLock);
    },
    async setCurrentStatus({ commit }, payload) {
      let currentStatus = await axios.post(
        `${ApiHost}/moove/api/otoplug/command/driving/currentstatus`,
        { carNo: payload }
      );
      commit("setCurrentStatus", currentStatus);
    },
    saveCarAlc({ commit }, payload) {
      let carAlcList = axios.post(`${ApiHost}/moove/api/caralc`, payload);

      commit("setCarAlcList", carAlcList.data);
      return carAlcList;
    },
    async updateCarAlc({commit}, payload) {
      return await axios.post(`${ApiHost}/moove/api/caralc/update`, payload);
    },
    setUseBgnDt({ commit }, payload) {
      let carAlc = axios.post(`${ApiHost}/moove/api/caralc/usebgndt`, payload);
    },
    async cancelCarAlc({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/caralc/cancel/${payload.histCreatId}`, payload);
    },
    async cancelCarAlcDtls({ commit }, payload) {
      // 삭제
      await axios.post(`${ApiHost}/moove/api/caralc/cancel/${payload.lgnId}`, payload);
      // 다시 로드
      var carAlcList = await axios.get(
        `${ApiHost}/moove/api/caralc/mobile_dtls` + "?rsvtState=20,10,30"
      );
      commit("setCarAlcMDtlsList", carAlcList.data);
    },
    async setCarAlcUse({ commit }, payload) {
      let carAlcList = await axios.get(`${ApiHost}/moove/api/caralc`, payload);
      commit("setCarAlcUse", carAlcList.data[0]);
    },
    async saveCaWrhousng({ commit }, payload) {
      let carAlcList = await axios.post(
        `${ApiHost}/moove/api/caralc/wrhousng`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      commit("setCarAlcUse", carAlcList);
    },
    // 배차코드 (공통코드)
    setRsvtStateList: ({ commit }, payload) => {
      axios
        .get(`${ApiHost}/moove/api/cd/details/gcd/` + payload.cd)
        .then((res) => {
          let list = res.data.length > 0 ? res.data : [];
          commit("setRsvtStateList", list);
        });
    },

    // 배차정보 리스트 - 사용중
    async setCarAlcUseList({ commit }, payload) {
      let param = `?rsvtState=${payload.rsvtState}`;
      if (!emptyCheck(payload.dptCd)) param = param.concat(`&dptCd=${payload.dptCd}`);
      const carAlcList = await axios.get(
          `${ApiHost}/moove/api/caralc/use/${payload.bizNo}` + param
      );
      commit("setCarAlcUseList", carAlcList.data);
    },
    // 배차정보 리스트 - 입고지연
    async setCarAlcWhnyList({ commit }, payload) {
      let param = `?rsvtState=${payload.rsvtState}`;
      if (!emptyCheck(payload.dptCd)) param = param.concat(`&dptCd=${payload.dptCd}`);
      const carAlcList = await axios.get(
          `${ApiHost}/moove/api/caralc/whny/${payload.bizNo}` + param
      );
      commit("setCarAlcWhnyList", carAlcList.data);
    },
    // 유종코드 (공통코드)
    setOilCdList: ({ commit }, payload) => {
      axios
        .get(`${ApiHost}/moove/api/cd/details/gcd/` + payload.cd)
        .then((res) => {
          let list = res.data.length > 0 ? res.data : [];
          commit("setOilCdList", list);
        });
    },
    // 배차정보 리스트 - 배차내역
    async setCarAlcDtlsList({ commit }, payload) {
      let param = `?rsvtState=${payload.rsvtState}&yyyyMM=${payload.yyyyMM}`;
      if (!emptyCheck(payload.dptCd)) param = param.concat(`&dptCd=${payload.dptCd}`);
      const carAlcList = await axios.get(
        `${ApiHost}/moove/api/caralc/dtls/${payload.bizNo}` + param
      );
      commit("setCarAlcDtlsList", carAlcList.data);
    },
    // 결재정보 - 히스토리
    async setCaHistList({ commit }, payload) {
      var caHistList = await axios.get(
        `${ApiHost}/moove/api/caralc/hist/` + payload.rsvtNo
      );
      commit("setCaHistList", caHistList.data);
    },
    // 결재요청 - 배차상태별 히스토리
    async setCarHistByRsvtState({commit}, payload) {
      let carHistList = await axios.get(
          `${ApiHost}/moove/api/caralc/rsvtHist/`
          + "?rsvtState="
          + payload.rsvtState
          + "&rsvtNo="
          + payload.rsvtNo
      );
      commit("setCarHistByRsvtState", carHistList.data);
    },
    /* 미사용 주석 처리 */
    // updateCarAlc(undefined, payload) {
    //   return axios.post(`${ApiHost}/moove/api/caralc/save`, payload);
    //   //      var carList = await axios.get(
    //   //        `${ApiHost}/moove/api/car/all/` + payload.bizNo
    //   //      );
    //   //      commit("setCarList", carList.data);
    // },
    // 입고정보
    async setCaWrhousng({ commit }, payload) {
      var caWrhousng = await axios.get(
        `${ApiHost}/moove/api/caralc/wrhousng/` + payload.rsvtNo
      );
      commit("setCaWrhousng", caWrhousng.data);
    },
    /**
     * 마지막 입고정보 조회
     * @param payload carNo
     */
    async setCarLastWh({commit}, payload) {
      const result = await axios.get(`${ApiHost}/moove/api/caralc/wrhousng/last/${payload}`);
      commit("setCarLastWh", result.data);
    },
    // 주차장정보
    async setCaParking({ commit }, payload) {
      var caParking = await axios.get(
        `${ApiHost}/moove/api/parking/` + payload.parklSeq
      );
      commit("setCaParking", caParking.data);
    },

    // 모바일 배차내역
    async setCarAlcMDtlsList({ commit }, payload) {
      var carAlcList = await axios.get(
        `${ApiHost}/moove/api/caralc/mobile_dtls` +
          "?rsvtState=" +
          payload.rsvtState
      );
      commit("setCarAlcMDtlsList", carAlcList.data);
    },
    // 운행일지 - 차량목록
    async setDailyCarList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var dailyCarList = await axios.get(
        `${ApiHost}/moove/api/caralc/dailycar/` +
          payload.bizNo +
          `/` +
          payload.strDt +
          `/` +
          payload.endDt,
        { params: params }
      );
      commit("setDailyCarList", dailyCarList.data);
    },
    // 운행일지 - 다운로드
    async setDailyTrpList({ commit }, payload) {
      var dailyTrpList = [];
      await payload.carNo.forEach(async function(ele) {
        var trpList = await axios.get(
          `${ApiHost}/moove/api/caralc/dailytrp/` +
            payload.bizNo +
            `/` +
            payload.strDt +
            `/` +
            payload.endDt +
            `?carNo=` +
            ele.carNo +
            `&dptCd=` +
            payload.dptCd
        );

        dailyTrpList.push({
          bizNo: payload.bizNo,
          dptCd: payload.dptCd,
          carNo: ele.carNo,
          carNm: ele.carNm,
          carTrpList: trpList.data,
        });

        await commit("setDailyTrpList", dailyTrpList);
      }, this);

    },
    setCarUseBeginTime({commit}, payload) {
      // MooveStartEndDateTimeDialog.vue
      commit("setCarUseBeginTime", payload);
    },
    setCarUseEndTime({commit}, payload) {
      // MooveStartEndDateTimeDialog.vue
      commit("setCarUseEndTime", payload);
    },
    async setCarUsingList({commit}, payload) {
      const result = (await axios.get(
          `${ApiHost}/moove/api/caralc/using/` +
          `${payload.carNo}/${payload.bgnDt}/${payload.endDt}` +
          `?bizNo=${payload.bizNo}&rsvtNo=${payload.rsvtNo}`
          )).data;
      commit("setCarUsingList", result);
      return result;
    },

    async setParklCar({ commit }, payload) {
      let parklCar = await axios.post(
        `${ApiHost}/moove/api/caralc/parklcar`, payload
      );
//      commit("setParklCar", parklCar);
    },
    setRsvtCalendarDialog({commit}, payload) {
      commit("setRsvtCalendarDialog", payload);
    },
    async setCarAlcCheckList({commit}, payload) {
      let param = `?yyyyMM=${payload.yyyyMM}`;
      if (!emptyCheck(payload.dptCd)) {
        param = param.concat(`&dptCd=${payload.dptCd}`);
      }
      const result = await axios.get(`${ApiHost}/moove/api/caralc/check/${payload.bizNo}${param}`);
      commit("setCarAlcCheckList", result.data);
      return result.data;
    },
    async setCarListByCarNo({commit}, payload) {
      const resultList = await axios.get(`${ApiHost}/moove/api/car/list/${payload.carNo}`);
      commit("setCarListByCarNo", resultList.data);
      return resultList.data;
    },
    async checkDuplicatedCarNo({commit}, payload) {
      const resultList = await axios.get(`${ApiHost}/moove/api/car/car-no/list?carList=${payload}`);
      return resultList.data;
    },
    async saveCarAlcCtrlHist({commit}, payload) {
      const result = await axios.post(`${ApiHost}/moove/api/caralc/ctrl-hist`, payload);
      if (result.data.statusCode !== 200) console.error(result.data.message);
      return result.data;
    },
    async setCar({commit}, payload) {
      const result = await axios.get(`${ApiHost}/moove/api/car/${payload}`);
      commit("setCar", result.data);
      return result.data;
    },
    // 운전자
    async setCaDriverMbr({ commit }, payload) {
      var driverMbr = await axios.get(
        `${ApiHost}/moove/api/caralc/driver/` + payload.carNo
      );
      commit("setCaDriverMbr", driverMbr.data);
    },

  },
};
