import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import parser from "xml-parser";
import router from "@/router";
import modules from "./modules";
import { ApiHost, JusoHost } from "./constants";
import {emptyCheck} from "@/utils/util";

Vue.use(Vuex);

const JusoKey = "U01TX0FVVEgyMDE5MTIwOTE3MDcwNjEwOTI5NDA="; //도로명주소 API KEY
const itsKey = "cebbcb877ef144faad5d1a06188b23b6"; //국가교통정보센터 API KEY
const itsUrl = "https://openapi.its.go.kr:9443"; //국가교통정보센터 API URL

var isMobile = function() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

var nativeMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i) == null ? false : true;
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null
      ? false
      : true;
  },
  any: function() {
    return nativeMobile.Android() || nativeMobile.iOS();
  },
};

axios.interceptors.request.use(
  (config) => {
    if (ApiHost !== config.url.substring(0, ApiHost.length)) {
      return config;
    } else {
      // if (localStorage.getItem("access_token") === null) return config;
      config.headers["Authorization"] =
        "Bearer " + localStorage.getItem("access_token");

      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    // if (error.response === undefined) {
    //     router.push({ name: "500" }).catch(()=>{});
    // }
    if (error.response.status === 401) {
      // 401 에러가 발생할 경우
      if (localStorage.getItem("refresh_token") !== null) {
        var token = await axios.post(`${ApiHost}/moove/api/member/refresh`, {
          refreshToken: localStorage.getItem("refresh_token"),
        });
        if (token.data.accessToken !== undefined) {
          localStorage.setItem("access_token", token.data.accessToken);
          localStorage.setItem("refresh_token", token.data.refreshToken);

          try {
            if (nativeMobile.any()) {
              if (nativeMobile.Android()) {
                console.log("nativeMobile.Android");
                if (
                  WebViewCallbackInterface !== null ||
                  WebViewCallbackInterface !== undefined
                ) {
                  WebViewCallbackInterface.setAppCarAlc(token.data.accessToken);
                }
              }
              if (nativeMobile.iOS()) {
                console.log("nativeMobile.iOS");
                webkit.messageHandlers.setAppCarAlc.postMessage(
                  token.data.accessToken
                );
              }
            }
          } catch (err) {
            console.log("WebViewCallbackInterface is not defined");
          }

          // 차량제어 명령 인증 - 차량제어 화면 접근시 1번->제어

          axios.defaults.headers.common.Authorization = `Bearer ${token.data.accessToken}`;
          originalRequest.headers.Authorization = `Bearer ${token.data.accessToken}`;
          return await axios(originalRequest);
        }
      } else {
        //refresh_token==null
        router.push({ name: "signin-mobile" }).catch(() => {});
      }
      return Promise.reject(error);
    }
    // 400 에러가 발생할 경우
    if (error.response.status === 400) {
      await localStorage.removeItem("access_token");
      await localStorage.removeItem("refresh_token");
      if (isMobile()) {
        router.push({ name: "signin-mobile" }).catch(() => {});
      } else {
        router.push({ name: "signin-desktop" }).catch(() => {});
      }
    }
    if (error.response.status === 500) {
      if (localStorage.getItem("refresh_token") === null)
        if (isMobile()) {
          router.push({ name: "signin-mobile" }).catch(() => {});
        } else {
          router.push({ name: "signin-desktop" }).catch(() => {});
        }
    }
    return Promise.reject(error);
  }
);

const store = new Vuex.Store({
  modules,
  state: {
    selectedCarNo: null,
    selectedCarType: null,
    selectedRsvtState: null,
    searchText: null,
    selectedMemberType: null,
    selectedMember: null,
    selectedNoticeType: null,
    selectedQnaType: null,
    selectedContentType: null,
    selectedDept: {},
    toggleDayMonth: 0,
    startDay: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1
    )
      .toISOString()
      .substr(0, 10),
    endDay: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .substr(0, 10),
    isgStartDay: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 10
    )
      .toISOString()
      .substr(0, 10),
    isgEndDay: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .substr(0, 10),
    etasHistoryEndDay: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
      .toISOString()
      .substr(0, 10),
    startMonth: new Date().toISOString().substr(0, 7),
    endMonth: new Date().toISOString().substr(0, 7),
    toDay: new Date().toISOString().substr(0, 10),
    startTime: "01시",
    endTime: "24시",
    target: true,
    header: {
      mnuTtl: "운행관제",
    },
    swipe: "half",
    dialog: false,
    dialogMooveDptAndCarNoDialog: false,
    fullscreenDialog: false,
    dialogMember: false,
    dialogMessage: "",
    dialogMessageList: [],
    drawer: {
      bottomMenuDrawer: false,
      rightNavigationDrawer: false,
      leftNavigationDrawer: false,
    },
    map: {
      mapTile: "normal",
      bounds: [],
      toggleMapOverlay: false,
      toggleMapCctvOverlay: false,
      toggleMapCctvLayer: false,
      toggleMapTrafficLayer: false,
    },
    rctOprList: [],
    rctOprInfo: {},
    rctOprListByDeptId: [],
    cctvList: [],
    progress: {
      loading: false,
      bufferValue: 0,
      value: 0,
    },
    spinner: false,
    bizTpList: [
      { name: "일반 화물자동차 운송업", bizTp: "11" },
      { name: "용달 화물자동차 운송업", bizTp: "49302" },
      { name: "개별 화물자동차 운송업", bizTp: "49303" },
      { name: "전세버스 운송업", bizTp: "49232" },
      { name: "시외버스 운송업", bizTp: "49220" },
      { name: "택시 운송업", bizTp: "49231" },
    ],
    moniTpList: [
      { name: "전체관제", moniTp: "01" },
      { name: "부서관제", moniTp: "02" },
      { name: "차량관제", moniTp: "03" },
    ],
    hhList: [
      { name: "00시", value: "0" },
      { name: "01시", value: "1" },
      { name: "02시", value: "2" },
      { name: "03시", value: "3" },
      { name: "04시", value: "4" },
      { name: "05시", value: "5" },
      { name: "06시", value: "6" },
      { name: "07시", value: "7" },
      { name: "08시", value: "8" },
      { name: "09시", value: "9" },
      { name: "10시", value: "10" },
      { name: "11시", value: "11" },
      { name: "12시", value: "12" },
      { name: "13시", value: "13" },
      { name: "14시", value: "14" },
      { name: "15시", value: "15" },
      { name: "16시", value: "16" },
      { name: "17시", value: "17" },
      { name: "18시", value: "18" },
      { name: "19시", value: "19" },
      { name: "20시", value: "20" },
      { name: "21시", value: "21" },
      { name: "22시", value: "22" },
      { name: "23시", value: "23" },
      { name: "24시", value: "24" },
    ],
    menuDesktop: [],
    menuMobile: [],
//    menuMobile: [
//      { mnuTtl: "운행관제", dfltYn: "y", mnuNm: "service-status", mnuIcon: "mdi-car" },
//      { mnuTtl: "배차신청", dfltYn: "n", mnuNm: "car-alc", mnuIcon: "mdi-table-large-plus" },
//      { mnuTtl: "차량이용", dfltYn: "n", mnuNm: "car-alc-use", mnuIcon: "mdi-car" },
//      { mnuTtl: "배차내역", dfltYn: "n", mnuNm: "car-alc-dtls", mnuIcon: "mdi-view-list" },
//      { mnuTtl: "관리자 원격개폐", dfltYn: "n", mnuNm: "car-alc-rcd", mnuIcon: "mdi-remote" },
//      { mnuTtl: "설정", dfltYn: "n", mnuNm: "mypage-list", mnuIcon: "mdi-cog" },
//    ],
    memberList: [],
    memberListAll: [],
    member: {},
    dupCheckMember: {},
    addressList: [],
    companyList: [],
    selectedRctOpr: {},
    dptList: [],
    originalDptList: [],
    selectedRctOprAddress: "주소 생성 중",
    selectedRctOprCarNo: "",
    accessToken: null,
    company: {},
    carList: [],
    selectedCarLastTrip: [],
    selectedRctOprTripList: [],
    selectedRctOprTrip: [],
    selectedRctOprFullTripList: [],
    searchRctOprList: [],
    selectedRctOprStartTrip: {},
    selectedRctOprEndTrip: {},
    selectedRctOprStartTripAddress: {},
    selectedRctOprEndTripAddress: {},
    etasSendHistoryList: [],
    rctOprListWithLtUnusdYn: [],
    selectedCarDriveRecordList: [],
    noticeList: [],
    selectedCctv: [],
    selectedCarTrip: [],
    memberListByDptCd: [],
    carListByDptCd: [],
    rctOprState: "",
    searchRctOprListByCarNo: "",
    parkingAddress: "",
    parking: {
      name: "",
    },
    parkingList: [],
    searchParklName: null,
    pushData: {},
    mapClickEvent: 0,
  },
  getters: {
    getSearchText: (state) => {
      return state.searchText;
    },
    getSelectedCarNo: (state) => {
      return state.selectedCarNo;
    },
    getSelectedCarType: (state) => {
      return state.selectedCarType;
    },
    getSelectedRsvtState: (state) => {
      return state.selectedRsvtState;
    },
    getSelectedMember: (state) => {
      return state.selectedMember;
    },
    getSelectedMemberType: (state) => {
      return state.selectedMemberType;
    },
    getSelectedNoticeType: (state) => {
      return state.selectedNoticeType;
    },
    getSelectedQnaType: (state) => {
      return state.selectedQnaType;
    },
    getSelectedContentType: (state) => {
      return state.selectedContentType;
    },
    getSelectedDept: (state) => {
      return state.selectedDept;
    },
    getToggleDayMonth: (state) => {
      return state.toggleDayMonth;
    },
    getStartDay: (state) => {
      // var today = new Date();
      // if (state.endDay === new Date().toISOString().substr(0, 8) + "01")
      //   return new Date(today.getFullYear(), today.getMonth(), 0)
      //     .toISOString()
      //     .substr(0, 10);
      return state.startDay;
    },
    getEndDay: (state) => {
      return state.endDay;
    },
    getToDay: (state) => {
      return state.toDay;
    },
    getIsgStartDay: (state) => {
      // var today = new Date();
      // if (state.endDay === new Date().toISOString().substr(0, 8) + "01")
      //   return new Date(today.getFullYear(), today.getMonth(), 0)
      //     .toISOString()
      //     .substr(0, 10);
      return state.isgStartDay;
    },
    getIsgEndDay: (state) => {
      return state.isgEndDay;
    },
    getEtasHistoryEndDay: (state) => {
      return state.etasHistoryEndDay;
    },
    getStartMonth: (state) => {
      return state.startMonth;
    },
    getEndMonth: (state) => {
      return state.endMonth;
    },
    getStartTime: (state) => {
      return state.startTime;
    },
    getEndTime: (state) => {
      return state.endTime;
    },
    getTarget: (state) => {
      return state.target;
    },
    getDialogMember: (state) => {
      return state.dialogMember;
    },
    getRctOprList: (state) => {
      if (state.rctOprState === "") {
        if (state.searchRctOprListByCarNo === "") return state.rctOprList;
        else
          return state.rctOprList.filter((item) =>
            item.carNo.includes(state.searchRctOprListByCarNo)
          );
      } else {
        if (state.searchRctOprListByCarNo === "")
          return state.rctOprList.filter(
            (item) => item.engStt === state.rctOprState
          );
        else
          return state.rctOprList
            .filter((item) => item.engStt === state.rctOprState)
            .filter((item) =>
              item.carNo.includes(state.searchRctOprListByCarNo)
            );
      }
    },
    getRctOprInfo: (state) => {
      return state.rctOprInfo;
    },
    getRctOprListOn: (state) => {
      if (state.searchRctOprListByCarNo === "")
        return state.rctOprList.filter((item) => item.engStt === "D");
      else
        return state.rctOprList
          .filter((item) => item.engStt === "D")
          .filter((item) => item.carNo.includes(state.searchRctOprListByCarNo));
    },
    getRctOprListOff: (state) => {
      return state.rctOprList.filter(
        (item) => item.engStt === "E" || item.engStt === null
      );
    },
    getRctOprTotalCount: (state) => {
      return state.rctOprList.length;
    },
    getRctOprEngineEndCount: (state) => {
      return state.rctOprList.filter(
        (item) => item.engStt === "E" || item.engStt === null
      ).length;
    },
    getRctOprEngineDrivngCount: (state) => {
      return state.rctOprList.filter((item) => item.engStt === "D").length;
    },
    getRctOprUnuseTotalCount: (state) => {
      return state.rctOprListWithLtUnusdYn.length;
    },
    getMapTile: (state) => {
      return state.map.mapTile;
    },
    getCctvList: (state) => {
      return state.cctvList;
    },
    getMapBounds: (state) => {
      return state.map.bounds;
    },
    getRightNavigationDrawer: (state) => {
      return state.drawer.rightNavigationDrawer;
    },
    getBottomSheetDrawer: (state) => {
      return state.drawer.bottomSheetDrawer;
    },
    getProgress: (state) => {
      return state.progress;
    },
    getMenuMobile: (state) => {
      return state.menuMobile;
    },
    getMenuDesktop: (state) => {
      return state.menuDesktop;
    },
    getMember: (state) => {
      var member = {
        ...state.member,
        cmpnNm: localStorage.getItem("cmpnNm"),
        bizNo: localStorage.getItem("bizNo"),
        lgnId: localStorage.getItem("lgnId"),
      };
      return member;
    },
    getDupCheckMember: (state) => {
      return state.dupCheckMember;
    },
    getAddressList: (state) => {
      return state.addressList;
    },
    getCompanyList: (state) => {
      if (state.companyList.length !== 0) return state.companyList;
      return state.companyList.map(function(ele) {
        var ele1 = {
          ...ele,
          //          wrkStTm: "09:00:00",
          //          wrkEndTm: "18:00:00",
        };
        return ele1;
      });
    },
    getSelectedRctOpr: (state) => {
      var selectedRctOpr = state.rctOprList.find((element) => {
        return state.selectedRctOprCarNo === element.carNo;
      });
      if (typeof selectedRctOpr !== "undefined") return selectedRctOpr;
      return {};
    },
    getOriginalDptList: (state) => {
      return state.originalDptList;
    },
    getDptList: (state) => {
      return state.dptList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.dptId,
        };
        return ele1;
      });
    },
    getRctOprListByDeptId: (state) => {
      return state.rctOprListByDeptId;
    },
    getSelectedRctOprAddress: (state) => {
      return state.selectedRctOprAddress;
    },
    getSelectedRctOprCarNo: (state) => {
      return state.selectedRctOprCarNo;
    },
    getCompany: (state) => {
      return state.company;
    },
    getCarList: (state) => {
      return state.carList;
    },
    getBizTpList: (state) => {
      return state.bizTpList;
    },
    getHhList: (state) => {
      return state.hhList;
    },
    getMoniTpList: (state) => {
      return state.moniTpList;
    },
    getMemberList: (state) => {
      return state.memberList;
    },
    getSwipe: (state) => {
      return state.swipe;
    },
    getSelectedCarLastTrip: (state) => {
      if (state.selectedCarLastTrip.length === 0) return [];
      var selectedCarLastTrip = state.selectedCarLastTrip.map(function(ele) {
        var coordinate = [parseFloat(ele.gsX), parseFloat(ele.gsY), ele.spd];
        return coordinate;
      });
      return selectedCarLastTrip;
    },
    getSelectedRctOprTripList: (state) => {
      return state.selectedRctOprTripList;
    },
    getSelectedRctOprTripMap: (state) => {
      if (state.selectedRctOprTripList === undefined) return null;
      var map = new Map();
      var drvDst = 0;
      var drvTm = 0;
      state.selectedRctOprTripList.forEach((element) => {
        var trpDt = map.get(element.trpDt);
        if (trpDt === undefined) {
          if (element.drvDst !== null) drvDst += parseInt(element.drvDst);
          if (element.drvTm !== null) drvTm = parseInt(element.drvTm);
          var ele = {
            ...element,

            totalDrvDst: drvDst,
            totalDrvTm: drvTm,
            children: [element],
          };
          drvTm = 0;
          map.set(element.trpDt, ele);
        } else {
          var ele2 = map.get(element.trpDt);
          if (element.drvDst !== null)
            ele2.totalDrvDst += parseInt(element.drvDst) - ele2.totalDrvDst;
          if (element.drvTm !== null)
            ele2.totalDrvTm += parseInt(element.drvTm);
          ele2.children.push(element);
        }
      });
      var mapDesc = new Map(
        [...map.entries()].sort(function(front, rear) {
          return parseInt(rear) - parseInt(front);
        })
      );
      return mapDesc;
    },
    getSelectedRctOprFullTripMap: (state) => {
      if (state.selectedRctOprFullTripList === undefined) return null;
      var map = new Map();
      var drvDst = 0;
      var drvTm = 0;
      state.selectedRctOprFullTripList.forEach((element) => {
        var trpDt = map.get(element.trpDt);
        if (trpDt === undefined) {
          if (element.drvDst !== null) drvDst += parseInt(element.drvDst);
          if (element.drvTm !== null) drvTm += parseInt(element.drvTm);
          var ele = {
            ...element,
            totalDrvDst: drvDst,
            totalDrvTm: drvTm,
            children: [element],
          };
          var key = element.trpDt.slice(0, 8);
          map.set(key, ele);
        } else {
          var ele2 = map.get(element.trpDt);
          if (element.drvDst !== null)
            ele2.totalDrvDst += parseInt(element.drvDst) - ele2.totalDrvDst;
          if (element.drvTm !== null)
            ele2.totalDrvTm += parseInt(element.drvTm) - ele2.totalDrvTm;
          ele2.children.push(element);
        }
      });
      return map;
    },
    getSelectedCarDriveRecordList: (state) => {
      var selectedCarDriveRecordList = state.selectedCarDriveRecordList.map(
        function(ele) {
          var coordinate = [parseFloat(ele.gsX), parseFloat(ele.gsY), ele.spd];
          return coordinate;
        }
      );
      return selectedCarDriveRecordList;
    },
    getSearchRctOprList: (state) => {
      return state.searchRctOprList;
    },
    getSelectedRctOprStartTrip: (state) => {
      return state.selectedRctOprStartTrip;
    },
    getSelectedRctOprEndTrip: (state) => {
      return state.selectedRctOprEndTrip;
    },
    getSelectedRctOprStartTripAddress: (state) => {
      return state.selectedRctOprStartTripAddress;
    },
    getSelectedRctOprEndTripAddress: (state) => {
      return state.selectedRctOprEndTripAddress;
    },
    getEtasSendHistoryList: (state) => {
      return state.etasSendHistoryList;
    },
    getRctOprListWithLtUnusdYn: (state) => {
      return state.rctOprListWithLtUnusdYn;
    },
    getNoticeList: (state) => {
      return state.noticeList;
    },
    getSelectedCctv: (state) => {
      return state.selectedCctv;
    },
    getToggleMapCctvOverlay: (state) => {
      return state.map.toggleMapCctvOverlay;
    },
    getToggleMapCctvLayer: (state) => {
      return state.map.toggleMapCctvLayer;
    },
    getToggleMapTrafficLayer: (state) => {
      return state.map.toggleMapTrafficLayer;
    },
    getDialogMooveDptAndCarNoDialog: (state) => {
      return state.dialogMooveDptAndCarNoDialog;
    },
    getSelectedRctOprTrip: (state) => {
      return state.selectedRctOprTrip;
    },
    getSelectedCarTrip: (state) => {
      return state.selectedCarTrip;
    },
    getMemberListByDptCd: (state) => {
      return state.memberListByDptCd;
    },
    getCarListByDptCd: (state) => {
      return state.carListByDptCd;
    },
    getParkingAddress: (state) => {
      return state.parkingAddress;
    },
    getParking: (state) => {
      return state.parking;
    },
    getParkingList: (state) => {
      if (state.searchParklName === "" || state.searchParklName === null)
        return state.parkingList;
      else
        return state.parkingList.filter((item) =>
          item.parklName.includes(state.searchParklName)
        );
    },
    getToggleMapOverlay: (state) => {
      return state.map.toggleMapOverlay;
    },
    getSearchParklName: (state) => {
      return state.searchParklName;
    },
    getPushData: (state) => {
      return state.pushData;
    },
    getMapClickEvent: (state) => {
      return state.mapClickEvent;
    },
    getSpinner: (state) => {
      return state.spinner;
    },
    getMemberListAll: (state) => {
      return state.memberListAll;
    },
  },
  mutations: {
    setSearchText: (state, payload) => {
      return (state.searchText = payload);
    },
    setSelectedCarType: (state, payload) => {
      state.selectedCarType = payload;
    },
    setSelectedRsvtState: (state, payload) => {
      state.selectedRsvtState = payload;
    },
    setSelectedMemberType: (state, payload) => {
      state.selectedMemberType = payload;
    },
    setSelectedMember: (state, payload) => {
      state.selectedMember = payload;
    },
    setSelectedNoticeType: (state, payload) => {
      state.selectedNoticeType = payload;
    },
    setSelectedQnaType: (state, payload) => {
      state.selectedQnaType = payload;
    },
    setSelectedContentType: (state, payload) => {
      state.selectedContentType = payload;
    },
    setSelectedCarNo: (state, payload) => {
      state.selectedCarNo = payload;
    },
    setSelectedDept: (state, payload) => {
      state.selectedDept = payload;
    },
    setToggleDayMonth: (state, payload) => {
      state.toggleDayMonth = payload;
    },
    setStartDay: (state, payload) => {
      state.startDay = payload;
    },
    setToDay: (state, payload) => {
      state.today = payload;
    },
    setEndDay: (state, payload) => {
      state.endDay = payload;
    },
    setIsgStartDay: (state, payload) => {
      state.isgStartDay = payload;
    },
    setIsgEndDay: (state, payload) => {
      state.isgEndDay = payload;
    },
    setEtasHistoryEndDay: (state, payload) => {
      state.etasHistoryEndDay = payload;
    },
    setStartMonth: (state, payload) => {
      state.startMonth = payload;
    },
    setEndMonth: (state, payload) => {
      state.endMonth = payload;
    },
    setStartTime: (state, payload) => {
      state.startTime = payload;
    },
    setEndTime: (state, payload) => {
      state.endTime = payload;
    },
    setSelectedCarTrip: (state, payload) => {
      state.selectedCarTrip = payload;
    },
    setRctOprList: (state, payload) => {
      state.rctOprList = payload;
    },
    setRctOprInfo: (state, payload) => {
      state.rctOprInfo = payload;
    },
    setMapTile: (state, payload) => {
      state.map.mapTile = payload.tileName;
    },
    setCctvList: (state, payload) => {
      state.cctvList = payload;
    },
    setMapBounds: (state, payload) => {
      state.map.bounds = payload;
    },
    toggleRrightNavigationDrawer: (state) => {
      state.drawer.rightNavigationDrawer = !state.drawer.rightNavigationDrawer;
    },
    loadingOn: (state) => {
      state.progress.loading = true;
    },
    loadingOff: (state) => {
      state.progress.loading = false;
    },
    setMenuDesktop: (state, payload) => {
      state.menuDesktop = payload;
    },
    setMenuMobile: (state, payload) => {
      state.menuMobile = payload;
    },
    setMember: (state, payload) => {
      state.member = payload;
    },
    setAddressList: (state, payload) => {
      state.addressList = payload;
    },
    setCompanyList: (state, payload) => {
      state.companyList = payload;
    },
    setSelectedRctOpr: (state, payload) => {
      state.selectedRctOpr = payload;
    },
    setRctOprListByDeptId: (state, payload) => {
      state.rctOprListByDeptId = payload;
    },
    setDupCheckMember: (state, payload) => {
      state.dupCheckMember = payload;
    },
    setOriginalDptList: (state, payload) => {
      state.originalDptList = payload;
    },
    setDptList: (state, payload) => {
      state.dptList = null;
      state.dptList = payload;
    },
    clearSelectedRctOprCarNo: (state) => {
      state.selectedRctOprCarNo = "";
    },
    setSelectedRctOprAddress: (state, payload) => {
      state.selectedRctOprAddress = payload;
    },
    setSelectedRctOprCarNo: (state, payload) => {
      state.selectedRctOprCarNo = payload;
    },
    setAccessToken: (state, payload) => {
      state.accessToken = payload;
    },
    setCompany: (state, payload) => {
      state.company = {
        ...payload,
        //        wrkEndTm:
        //          payload.wrkEndTm.substring(0, 2) +
        //          ":" +
        //          payload.wrkEndTm.substring(2, 4),
        //        wrkStTm:
        //          payload.wrkStTm.substring(0, 2) +
        //          ":" +
        //          payload.wrkStTm.substring(2, 4),
      };
    },
    setCarList: (state, payload) => {
      state.carList = payload;
    },
    setBizTpList: (state, payload) => {
      state.bizTpList = payload;
    },
    setMoniTpList: (state, payload) => {
      state.moniTpList = payload;
    },
    setMemberList: (state, payload) => {
      state.memberList = payload;
    },
    setSwipe: (state, payload) => {
      state.swipe = payload;
    },
    setSelectedCarLastTrip: (state, payload) => {
      state.selectedCarLastTrip = payload;
    },
    clearSelectedCarLastTrip: (state) => {
      state.selectedCarLastTrip = [];
    },
    setSelectedRctOprTripList: (state, payload) => {
      state.selectedRctOprTripList = payload;
    },
    clearSelectedRctOprTripList: (state) => {
      state.selectedRctOprTripList = [];
    },
    setSelectedCarDriveRecordList: (state, payload) => {
      state.selectedCarDriveRecordList = payload;
    },
    clearSelectedCarDriveRecordList: (state) => {
      state.selectedCarDriveRecordList = [];
    },
    setHeaderTitle: (state) => {
      state.header.mnuTtl = state;
    },
    setSelectedRctOprFullTripList: (state, payload) => {
      state.selectedRctOprFullTripList = payload;
    },
    clearSelectedRctOprFullTripList: (state) => {
      state.selectedRctOprFullTripList = [];
    },
    setSearchRctOprList: (state, payload) => {
      state.searchRctOprList = payload;
    },
    clearSearchRctOprList: (state) => {
      state.searchRctOprList = [];
    },
    clearRctOprList: (state) => {
      state.rctOprList = [];
    },
    clearSelectedRctOpr: (state) => {
      state.selectedRctOpr = {};
    },
    setSelectedRctOprStartTrip: (state, payload) => {
      state.selectedRctOprStartTrip = payload;
    },
    setSelectedRctOprEndTrip: (state, payload) => {
      state.selectedRctOprEndTrip = payload;
    },
    setSelectedRctOprStartTripAddress: (state, payload) => {
      state.selectedRctOprStartTripAddress = payload;
    },
    setSelectedRctOprEndTripAddress: (state, payload) => {
      state.selectedRctOprEndTripAddress = payload;
    },
    clearSelectedRctOprStartTripAddress: (state) => {
      state.selectedRctOprStartTripAddress = {};
    },
    clearSelectedRctOprEndTripAddress: (state) => {
      state.selectedRctOprEndTripAddress = {};
    },
    setEtasSendHistoryList: (state, payload) => {
      state.etasSendHistoryList = payload;
    },
    setRctOprListWithLtUnusdYn: (state, payload) => {
      state.rctOprListWithLtUnusdYn = payload;
    },
    closefullscreenDialog: (state) => {
      state.fullscreenDialog = false;
    },
    setNoticeList: (state, payload) => {
      state.noticeList = payload;
    },
    setSelectedCctv: (state, payload) => {
      state.selectedCctv = payload;
    },
    setSelectedRctOprTrip: (state, payload) => {
      state.selectedRctOprTrip = payload;
    },
    setMemberListByDptCd: (state, payload) => {
      state.memberListByDptCd = payload;
    },
    setCarListByDptCd: (state, payload) => {
      state.carListByDptCd = payload;
    },
    clearStartDay: (state) => {
      state.startDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
        .toISOString()
        .substr(0, 10);
    },
    clearEndDay: (state) => {
      state.endDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .substr(0, 10);
    },
    clearStartMonth: (state) => {
      state.startMonth = new Date().toISOString().substr(0, 7);
    },
    clearEndMonth: (state) => {
      state.endMonth = new Date().toISOString().substr(0, 7);
    },
    clearIsgStartDay: (state) => {
      state.isgStartDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 10
      )
        .toISOString()
        .substr(0, 10);
    },
    clearIsgEndDay: (state) => {
      state.isgEndDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .substr(0, 10);
    },
    clearEtasHistoryEndDay: (state) => {
      state.etasHistoryEndDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      )
        .toISOString()
        .substr(0, 10);
    },
    clearSelectedDept: (state) => {
      state.selectedDept = {};
    },
    clearSelectedCarNo: (state) => {
      state.selectedCarNo = null;
    },
    setRctOprState: (state, payload) => {
      state.rctOprState = payload;
    },
    setSearchRctOprListByCarNo: (state, payload) => {
      state.searchRctOprListByCarNo = payload;
    },
    clearSearchRctOprListByCarNo: (state) => {
      state.searchRctOprListByCarNo = "";
    },
    setParkingAddress: (state, payload) => {
      state.parkingAddress = payload;
    },
    setParking: (state, payload) => {
      state.parking = payload;
      state.parkingAddress.address = payload.adr;
      state.parkingAddress.xCrd = payload.xCrd;
      state.parkingAddress.yCrd = payload.yCrd;
    },
    setParkingList: (state, payload) => {
      state.parkingList = payload;
    },
    clearParking: (state) => {
      state.parking = {};
    },
    clearParkingAddress: (state) => {
      state.parkingAddress = "";
    },
    setToggleMapOverlay: (state, payload) => {
      state.map.toggleMapOverlay = payload;
    },
    setSearchParklName: (state, payload) => {
      state.searchParklName = payload;
    },
    setPushData: (state, payload) => {
      state.pushData = payload;
    },
    setMapClickEvent: (state, payload) => {
      state.mapClickEvent = payload;
    },
    setSpinner: (state, payload) => {
      state.spinner = payload;
    },
    setMemberListAll: (state, payload) => {
      state.memberListAll = payload;
    },
  },
  actions: {
    setSearchText: ({ commit }, payload) => {
      commit("setSearchText", payload);
    },
    setSelectedCarNo: ({ commit }, payload) => {
      commit("setSelectedCarNo", payload);
    },
    setSelectedCarType: ({ commit }, payload) => {
      commit("setSelectedCarType", payload);
    },
    setSelectedRsvtState: ({ commit }, payload) => {
      commit("setSelectedRsvtState", payload);
    },
    setSelectedMemberType: ({ commit }, payload) => {
      commit("setSelectedMemberType", payload);
    },
    setSelectedMember: ({ commit }, payload) => {
      commit("setSelectedMember", payload);
    },
    setSelectedNoticeType: ({ commit }, payload) => {
      commit("setSelectedNoticeType", payload);
    },
    setSelectedQnaType: ({commit}, payload) => {
      commit("setSelectedQnaType", payload);
    },
    setSelectedContentType: ({commit}, payload) => {
      commit("setSelectedContentType", payload);
    },
    setSelectedDept: ({ commit }, payload) => {
      commit("setSelectedDept", payload);
    },
    setToggleDayMonth: ({ commit }, payload) => {
      commit("setToggleDayMonth", payload);
    },
    setStartDay: ({ commit }, payload) => {
      commit("setStartDay", payload);
    },
    setEndDay: ({ commit }, payload) => {
      commit("setEndDay", payload);
    },
    setToDay: ({ commit }, payload) => {
      commit("setToDay", payload);
    },
    setStartMonth: ({ commit }, payload) => {
      commit("setStartMonth", payload);
    },
    setEndMonth: ({ commit }, payload) => {
      commit("setEndMonth", payload);
    },
    setIsgStartDay: ({ commit }, payload) => {
      commit("setIsgStartDay", payload);
    },
    setIsgEndDay: ({ commit }, payload) => {
      commit("setIsgEndDay", payload);
    },
    setEtasHistoryEndDay: ({ commit }, payload) => {
      commit("setEtasHistoryEndDay", payload);
    },
    setStartTime: ({ commit }, payload) => {
      commit("setStartTime", payload);
    },
    setEndTime: ({ commit }, payload) => {
      commit("setEndTime", payload);
    },
    async setRctOprList({ commit, dispatch }, payload) {
      const bizNo = localStorage.getItem("bizNo");
      if (bizNo === null) await dispatch("setMember");

      let param = "";
      if (!emptyCheck(payload?.carNo)) param = `?carNo=${payload.carNo}`;
      const rctOpr = await axios.get(
          `${ApiHost}/moove/api/recent-opreate/${bizNo}/page/0/30` + param
      );

      Promise.all(
        rctOpr.data.elements.map(function(ele) {
          var body = {
            x: ele.x,
            y: ele.y,
          };
          var ele1 = {
            ...ele,
            address: "",
          };
          axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, body)
            .then((res) => {
              ele1.address = res.data;
            });
          return ele1;
        })
      ).then((val) => {
        commit("setRctOprList", val);
      });
      // commit("setRctOprList", rctOpr.data.elements);
    },
    async setRctOprInfo({commit}, payload) {
      const result = await axios.get(
          `${ApiHost}/moove/api/recent-operate/info` +
          "?carNo=" +
          payload
      );
      commit("setRctOprInfo", result.data);
    },
    setMapTile: ({ commit }, payload) => {
      commit("setMapTile", payload);
    },
    async setCctvList({ commit }, payload) {

      /**
       * @param apiKey: 공개키
       * @param cctvType: CCTV 유형(1: 실시간 스트리밍(HLS) / 2: 동영상 파일 / 3: 정지 영상)
       * @param type: 도로 유형(ex: 고속도로 / its: 국도)
       * @param getType: 출력 결과 형식(xml, json / 기본: xml)
       * @param minX: 최소 경도 영역
       * @param maxX: 최대 경도 영역
       * @param minY: 최소 위도 영역
       * @param maxY: 최대 위도 영역
       */

      // 고속도로 cctv
      let exCctvList = await axios.get(
          `${itsUrl}/cctvInfo?apiKey=${itsKey}&cctvType=2` +
          "&minX=" +
          payload.minX +
          "&maxX=" +
          payload.maxX +
          "&minY=" +
          payload.minY +
          "&maxY=" +
          payload.maxY +
          "&type=ex&getType=xml"
      );

      // 국도 cctv
      let itsCctvList = await axios.get(
          `${itsUrl}/cctvInfo?apiKey=${itsKey}&cctvType=2` +
          "&minX=" +
          payload.minX +
          "&maxX=" +
          payload.maxX +
          "&minY=" +
          payload.minY +
          "&maxY=" +
          payload.maxY +
          "&type=its&getType=xml"
      );

      const exData = parser(exCctvList.data);
      const itsData = parser(itsCctvList.data);
      exCctvList = exData.root.children.filter((cctv) => {
        return cctv.name === "data";
      });
      itsCctvList = itsData.root.children.filter((cctv) => {
        return cctv.name === "data";
      });
      const cctvList = exCctvList.concat(itsCctvList);
      commit("setCctvList", cctvList);

    },
    setMapBounds: ({ commit }, payload) => {
      commit("setMapBounds", payload);
    },
    setSelectedRctOpr: ({ commit }, payload) => {
      commit("setSelectedRctOpr", payload);
    },
    toggleRrightNavigationDrawer: ({ commit }) => {
      commit("toggleRrightNavigationDrawer");
    },
    loadingOn: ({ commit }) => {
      setTimeout(() => {
        commit("loadingOff");
      }, 2000);
      commit("loadingOn");
    },
    loadingOff: ({ commit }) => {
      commit("loadingOff");
    },
    async setMenuMobile({ commit }, payload) {
      var menu = await axios.get(`${ApiHost}/moove/api/menu/` + payload);
      commit("setMenuMobile", menu.data);
    },
    async setMenuDesktop({ commit }, payload) {
      var menu = await axios.get(`${ApiHost}/moove/api/menu/` + payload);
      // 메뉴관리에 추가함 (2022/01/28)
//      menu.data.push({
//        mnuIcon: "mdi-parking",
//        mnuNm: "repair",
//        mnuTtl: "차량점검 정비",
//        children: [
//          {
//            defltYn: "n",
//            mnuNm: "repairList",
//            mnuTtl: "정비 관리",
//            url: "/repair/list",
//          },
//        ],
//      });
      commit("setMenuDesktop", menu.data);
    },
    setAddress: ({ commit }, payload) => {
      axios
        .get(
          `${JusoHost}/addrlink/addrLinkApiJsonp.do?confmKey=${JusoKey}&currentPage=1&countPerPage=50&keyword=` +
            payload +
            "&resultType=json"
        )
        .then((res) => {
          var str = res.data;
          str.substr(1, str.length - 1);
          var result = JSON.parse(str.substr(1, str.length - 2));
          var juso = result.results.juso;
          if (juso.length !== 0) {
            commit("setAddressList", juso);
          }
        });
      // axios
      //   .get(
      //     `${ApiHost}/address?confmKey=${JusoKey}&currentPage=1&countPerPage=50&keyword=` +
      //       payload +
      //       "&resultType=json"
      //   )
      //   .then((res) => {
      //     var str = res.data;
      //     str.substr(1, str.length - 1);
      //     var result = JSON.parse(str.substr(1, str.length - 2));
      //     var juso = result.results.juso;
      //     if (juso.length !== 0) {
      //       commit("setAddressList", juso);
      //     }
      //   });
    },
    setCompanyList: ({ commit }) => {
      axios.get(`${ApiHost}/moove/api/company`).then((res) => {
        commit("setCompanyList", res.data);
      });
    },
    async setCompany({ commit }, payload) {
      var company = await axios.get(
        `${ApiHost}/moove/api/company/` + payload.bizNo
      );
      localStorage.setItem("cmpnNm", company.data.cmpnNm);
      localStorage.setItem("calcCnfm", company.data.calcCnfm);
      commit("setCompany", company.data);
    },
    setOriginalDptList: ({commit}, payload) => {
      axios.get(`${ApiHost}/moove/api/dpt/list/${payload}`)
            .then(v => commit("setOriginalDptList", v.data));
    },
    setDptList: ({ commit }, payload) => {
      axios.get(`${ApiHost}/moove/api/dept/` + payload.bizNo).then((res) => {
        commit("setDptList", res.data);
      });
    },
    setRctOprListByDeptId: ({ commit }, payload) => {
      axios
        .get(
          `${ApiHost}/moove/api/recent-opreate/` +
            payload.bizNo +
            `?deptId=` +
            payload.dptId
        )
        .then((res) => {
          commit("setRctOprListByDeptId", res.data);
        });
    },
    clearSelectedRctOprCarNo: ({ commit }) => {
      commit("clearSelectedRctOprCarNo");
    },
    async setSelectedRctOprAddress({ commit }, payload) {
      // var address = await axios.get(
      //   `${nominatimHost}/search.php?q=` +
      //     payload.y +
      //     `%2C` +
      //     payload.x +
      //     `&format=json`
      // );
      var address = await axios.post(
        `${ApiHost}/moove/api/geocoding/reverse`,
        payload
      );
      commit("setSelectedRctOprAddress", address.data);
    },
    setSelectedRctOprCarNo: ({ commit }, payload) => {
      commit("setSelectedRctOprCarNo", payload);
    },
    async setSelectedCarTrip({ commit }, payload) {
      commit("setSelectedCarTrip", payload);
    },
    async setMember({ commit, dispatch }) {
      var member = await axios.get(`${ApiHost}/moove/api/member/profile`);
      localStorage.setItem("bizNo", member.data.bizNo);
      localStorage.setItem("lgnId", member.data.lgnId);
      localStorage.setItem("authId", member.data.authId);
      localStorage.setItem("dptCd", member.data.dptCd);
      localStorage.setItem("userDptCd", member.data.userDptCd);
      await dispatch("setCompany", member.data);
      // await dispatch("setMenuDesktop", member.data.lgnId);
      await commit("setMember", member.data);
    },
    async saveDpt({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/dept/`, payload);
      var dptList = await axios.get(
        `${ApiHost}/moove/api/dept/` + payload.bizNo
      );
      commit("setDptList", dptList.data);
    },
    async updateDpt({ commit }, payload) {
      await axios.put(`${ApiHost}/moove/api/dept/`, payload);
      axios.get(`${ApiHost}/moove/api/dept/` + payload.bizNo).then((res) => {
        commit("setDptList", res.data);
      });
    },
    async deleteDept({ commit }, payload) {
      await axios.delete(
        `${ApiHost}/moove/api/dept/` +
          this.state.member.bizNo +
          `?dptId=` +
          payload.dptId
      );
      axios.get(`${ApiHost}/moove/api/dept/` + payload.bizNo).then((res) => {
        commit("setDptList", res.data);
      });
    },
    async setLogin({ commit }, payload) {
      let response = (await axios.post(`${ApiHost}/moove/api/member/signin`, payload)).data;

      if (response.statusCode !== 200) return response;

      // 하이브리드앱에 토큰 전달
      try {
        if (nativeMobile.any()) {
          if (nativeMobile.Android()) {
            console.log("setLogin - nativeMobile.Android");
            if (
              WebViewCallbackInterface !== null ||
              WebViewCallbackInterface !== undefined
            ) {
              WebViewCallbackInterface.setAppCarAlc(response.data.accessToken);
            }
          }
          if (nativeMobile.iOS()) {
            console.log("setLogin - nativeMobile.iOS");
            webkit.messageHandlers.setAppCarAlc.postMessage(
                response.data.accessToken
            );
          }
        }
      } catch (err) {
        console.log("WebViewCallbackInterface is not defined");
      }
      
      // 2차인증 하는 경우
      if(payload.flag=="AUTH") {
          await localStorage.setItem("tmp_access_token", response.data.accessToken);
          await localStorage.setItem("tmp_refresh_token", response.data.refreshToken);
      }
      else
      {
          await localStorage.setItem("access_token", response.data.accessToken);
          await localStorage.setItem("refresh_token", response.data.refreshToken);
      }
      localStorage.setItem("bizNo", response.data.bizNo);
      localStorage.setItem("lgnId", response.data.lgnId);
      commit("setMember", response.data);
      return response;
    },
    setToken: ({ commit }) => {
	localStorage.setItem("access_token", localStorage.getItem("tmp_access_token"));
	localStorage.setItem("refresh_token", localStorage.getItem("tmp_refresh_token"));
	
	localStorage.removeItem("tmp_access_token");
	localStorage.removeItem("tmp_refresh_token");
    },
    async updateCompany({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/company`, payload);
      await axios
        .get(`${ApiHost}/moove/api/company/` + payload.bizNo)
        .then((res) => {
          commit("setCompany", res.data);
        });
    },
    async setCarList({ commit }) {
      var carList = await axios.get(
        `${ApiHost}/moove/api/car/all/` + this.state.member.bizNo
        // `${ApiHost}/moove/api/car/all/` + this.state.member.bizNo
      );
      commit("setCarList", carList.data);
    },
    async updateCar({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/car`, payload);
      //      await axios.post(`${ApiHost}/moove/api/link/dept/car`, payload);
      var carList = await axios.get(
        `${ApiHost}/moove/api/car/all/` + payload.bizNo
      );
      commit("setCarList", carList.data);
    },
    async deleteCar({ commit }, payload) {
      await axios.delete(`${ApiHost}/moove/api/car/` + payload.carId);
      await axios
        .get(`${ApiHost}/moove/api/car/all/` + payload.bizNo)
        .then((res) => {
          commit("setCarList", res.data);
        });
    },
    async setBizTpList({ commit }, payload) {
      var codeList = await axios.get(
        `${ApiHost}/moove/api/code/` + payload.bizNo
      );
      commit("setBizTpList", codeList.data);
    },
    async setMoniTpList({ commit }, payload) {
      var codeList = await axios.get(
        `${ApiHost}/moove/api/code/` + payload.bizNo
      );
      commit("setMoniTpList", codeList.data);
    },
    async setMemberList({ commit }, payload) {
      var memberList = await axios.get(
        `${ApiHost}/moove/api/member/` + payload.bizNo
      );
      commit("setMemberList", memberList.data);
    },
    async setMemberListAll({ commit }, payload) {
      // endDt 구분 없이 전체 조회
      const result = await axios.get(`${ApiHost}/moove/api/member/all/${payload}`);
      commit("setMemberListAll", result.data);
      return result.data;
    },
    async updateMember({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/member`, payload);
      var memberList = await axios.get(
        `${ApiHost}/moove/api/member/` + payload.bizNo
      );
      commit("setMemberList", memberList.data);
    },
    async updateMemberPasswordOwn({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/member/password`, payload);
      var member = await axios.get(`${ApiHost}/moove/api/member/`);
      commit("setMember", member.data);
      return;
    },
    async updateMemberPassword({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/member/password`, payload);
      // var member = await axios.get(`${ApiHost}/moove/api/member/`);
      // commit("setMember", member.data);
      //        .then((res) => {
      //          var memberList = axios.get(`${ApiHost}/moove/api/member/`);
      //          commit("setMemberList", memberList.data);
      //        })
    },
    async deleteMember({ commit }, payload) {
      await axios.delete(`${ApiHost}/moove/api/member/` + payload.mbrId)
          .then(() => axios.delete(`${ApiHost}/moove/api/member/dpt/delete/${payload.lgnId}`));
      var memberList = await axios.get(`${ApiHost}/moove/api/member`);
      commit("setMember", memberList.data);
    },
    setDupCheckMember({ commit }, payload) {
      return axios
        .get(`${ApiHost}/moove/api/member/dupcheck/` + payload.lgnId)
        .then((res) => {
          commit("setDupCheckMember", res.data);
        });
    },
    setDptListByCar: ({ commit }, payload) => {
      axios.get(`${ApiHost}/moove/api/dept/` + payload.bizNo).then((res) => {
        commit("setDptList", res.data);
      });
    },
    setSwipe: ({ commit }, payload) => {
      commit("setSwipe", payload);
    },
    setSelectedCarLastTrip: ({ commit }, payload) => {
      if (payload.trpSeq === null) return;
      axios
        .get(
          `${ApiHost}/moove/api/car/drvrcd/` +
            payload.carNo +
            `/` +
            payload.trpSeq +
            "?interval=10"
        )
        .then((res) => {
          commit("setSelectedCarLastTrip", res.data);
        });
    },
    clearSelectedCarLastTrip: ({ commit }) => {
      commit("clearSelectedCarLastTrip");
    },
    async setSelectedRctOprTripList({ commit }, payload) {
      var tripList = await axios.get(
        `${ApiHost}/moove/api/car/trp/` +
          payload.carNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay
      );
      var previousTripDst = 0;
      var previousTrpDt = 0;
      var modifyTripList = tripList.data.map(function(ele) {
        var tripDstMod = 0;

        tripDstMod = ele.drvDst - previousTripDst;
        previousTripDst = ele.drvDst;
        if (previousTrpDt !== ele.trpDt) tripDstMod = ele.drvDst;
        previousTrpDt = ele.trpDt;
        var modifyElement = {
          ...ele,
          tripDstMod: tripDstMod,
        };
        return modifyElement;
      });
      commit("setSelectedRctOprTripList", modifyTripList);
    },
    clearSelectedRctOprTripList: ({ commit, dispatch }) => {
      dispatch("clearSelectedRctOprStartTripAddress");
      commit("clearSelectedRctOprTripList");
    },
    setSelectedRctOprTrip: ({ commit }, payload) => {
      commit("setSelectedRctOprTrip", payload);
    },
    async setSelectedRctOprStartTripAddress({ commit }, payload) {
      // var address = await axios.get(
      //   `${nominatimHost}/search.php?q=` +
      //     payload.gsY +
      //     `%2C` +
      //     payload.gsX +
      //     `&format=json`
      // );
      var address = await axios.post(
        `${ApiHost}/moove/api/geocoding/reverse`,
        payload
      );
      commit("setSelectedRctOprStartTripAddress", address.data);
    },
    async setSelectedRctOprEndTripAddress({ commit }, payload) {
      // var address = await axios.get(
      //   `${nominatimHost}/search.php?q=` +
      //     payload.gsY +
      //     `%2C` +
      //     payload.gsX +
      //     `&format=json`
      // );
      var address = await axios.post(
        `${ApiHost}/moove/api/geocoding/reverse`,
        payload
      );
      commit("setSelectedRctOprEndTripAddress", address.data);
    },
    async setSelectedCarAllDriveRecordList({ commit, dispatch }, payload) {
      var trip = [];
      Promise.all(
        payload.trpSeqs.map(async function(ele) {
          var atrip = await axios.get(
            `${ApiHost}/moove/api/car/drvrcd/` +
              payload.carNo +
              `/` +
              ele +
              "?interval=10"
          );

          atrip.data;
          return atrip.data;
        })
      ).then((val) => {
        val.map(function(ele) {
          ele.map(function(ele1) {
            return trip.push(ele1);
          });
        });

        commit("setSelectedRctOprStartTrip", trip[0]);
        commit("setSelectedRctOprEndTrip", trip[trip.length - 1]);
        commit("setSelectedCarDriveRecordList", trip);
        var startBody = {
          x: trip[0].gsX,
          y: trip[0].gsY,
        };
        dispatch("setSelectedRctOprStartTripAddress", startBody);
        var endBody = {
          x: trip[trip.length - 1].gsX,
          y: trip[trip.length - 1].gsY,
        };
        dispatch("setSelectedRctOprEndTripAddress", endBody);
      });
    },
    async setSelectedCarDriveRecordList({ commit, dispatch }, payload) {
      var trip = await axios.get(
        `${ApiHost}/moove/api/car/drvrcd/` +
          payload.carNo +
          `/` +
          payload.trpSeq +
          "?interval=10"
      );
      commit("setSelectedRctOprStartTrip", trip.data[0]);
      commit("setSelectedRctOprEndTrip", trip.data[trip.data.length - 1]);
      await commit("setSelectedCarDriveRecordList", trip.data);
      // await commit("setSelectedRctOprTrip", trip.data);
      var startBody = {
        x: trip.data[0].gsX,
        y: trip.data[0].gsY,
      };
      dispatch("setSelectedRctOprStartTripAddress", startBody);
      var endBody = {
        x: trip.data[trip.data.length - 1].gsX,
        y: trip.data[trip.data.length - 1].gsY,
      };
      dispatch("setSelectedRctOprEndTripAddress", endBody);
    },
    clearSelectedCarDriveRecordList: ({ commit }) => {
      commit("clearSelectedCarDriveRecordList");
    },
    async setHeaderTitle({ commit }, payload) {
      commit("setHeaderTitle", payload);
    },
    async setSelectedRctOprFullTripList({ commit }, payload) {
      var tripList = await axios.get(
        `${ApiHost}/moove/api/car/trp/` +
          payload.carNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay
      );
      commit("setSelectedRctOprFullTripList", tripList.data);
    },
    clearSelectedRctOprFullTripList: ({ commit }) => {
      commit("clearSelectedRctOprFullTripList");
    },
    setSearchRctOprListByCarNo: ({ commit }, payload) => {
      commit("setSearchRctOprListByCarNo", payload);
    },
    clearSearchRctOprListByCarNo: ({ commit }) => {
      commit("clearSearchRctOprListByCarNo");
    },
    clearSearchRctOprList: ({ commit }) => {
      commit("clearSearchRctOprList");
    },
    clearRctOprList: ({ commit }) => {
      commit("clearRctOprList");
    },
    clearSelectedRctOpr: ({ commit }) => {
      commit("clearSelectedRctOpr");
    },
    clearSelectedRctOprStartTripAddress: ({ commit }) => {
      commit("clearSelectedRctOprStartTripAddress");
    },
    clearSelectedRctOprEndTripAddress: ({ commit }) => {
      commit("clearSelectedRctOprEndTripAddress");
    },
    async setEtasSendHistoryList({ commit }) {
      var payload2 = {
        carNo: "61소0384",
        startDay: "20200101",
        endDay: "20200213",
      };
      var etasSendlist = await axios.get(
        `${ApiHost}/moove/api/etas/send-history/` +
          payload2.carNo +
          `/` +
          payload2.startDay +
          `/` +
          payload2.endDay
      );
      commit("setEtasSendHistoryList", etasSendlist.data);
    },
    async setRctOprListWithLtUnusdYn({ commit }) {
      const authId = localStorage.getItem("authId");
      let payload = `&dptCd=${localStorage.getItem("dptCd")}`
      if (authId === '3' || authId === '26' || authId === '29') payload = "";

      const rctOpr = await axios.get(
          `${ApiHost}/moove/api/recent-opreate/${this.state.member.bizNo}` +
          `?ltUnusdYn=Y` +
          payload
      );
      Promise.all(
        rctOpr.data.map(function(ele) {
          const body = {
            x: ele.x,
            y: ele.y,
          };
          const ele1 = {
            ...ele,
            address: "",
          };
          axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, body)
            .then((res) => {
              ele1.address = res.data;
            });
          return ele1;
        })
      ).then((val) => {
        commit("setRctOprListWithLtUnusdYn", val);
      });
      commit("setRctOprListWithLtUnusdYn", rctOpr.data);
    },
    closefullscreenDialog: ({ commit }) => {
      commit("closefullscreenDialog");
    },
    async setNoticeList({ commit }) {
      var noticeList = await axios.get(`${ApiHost}/moove/api/notice`);
      commit("setNoticeList", noticeList.data);
    },
    setSelectedCctv: ({ commit }, payload) => {
      commit("setSelectedCctv", payload);
    },
    async setMemberListByDptCd({ commit }, dptModel) {
      const params = new URLSearchParams();
      params.append("dptCd", dptModel.dptCd);
      var memberListByDptCd = await axios.get(
        `${ApiHost}/moove/api/member/` + dptModel.bizNo,
        {
          params: params,
        }
      );
      commit("setMemberListByDptCd", memberListByDptCd.data);
    },
    async setParkingAddress({ commit }, coordinate) {
      var body = {
        x: coordinate[0],
        y: coordinate[1],
      };
      var address = await axios.post(
        `${ApiHost}/moove/api/geocoding/reverse`,
        body
      );
      var address1 = {
        adr: address.data,
        xCrd: coordinate[0],
        yCrd: coordinate[1],
      };
      commit("setParkingAddress", address1);
    },
    async saveParking({ commit }, payload) {
      await axios.post(`${ApiHost}/moove/api/parking`, payload);
    },
    async setCarListByDptCd({ commit }, dptModel) {
      const params = new URLSearchParams();
      if (dptModel.dptCd != null) {
        params.append("dptCd", dptModel.dptCd);
      }
      var carListByDptCd = await axios.get(
        `${ApiHost}/moove/api/car/all/` + dptModel.bizNo,
        {
          params: params,
        }
      );
      commit("setCarListByDptCd", carListByDptCd.data);
    },
    clearStartDay: ({ commit }) => {
      commit("clearStartDay");
    },
    clearEndDay: ({ commit }) => {
      commit("clearEndDay");
    },
    clearIsgStartDay: ({ commit }) => {
      commit("clearIsgStartDay");
    },
    clearIsgEndDay: ({ commit }) => {
      commit("clearIsgEndDay");
    },
    clearEtasHistoryEndDay: ({ commit }) => {
      commit("clearEtasHistoryEndDay");
    },
    clearSelectedDept: ({ commit }) => {
      commit("clearSelectedDept");
    },
    clearSelectedCarNo: ({ commit }) => {
      commit("clearSelectedCarNo");
    },
    setRctOprState({ commit }, payload) {
      commit("setRctOprState", payload);
    },
    clearStartMonth: ({ commit }) => {
      commit("clearStartMonth");
    },
    clearEndMonth: ({ commit }) => {
      commit("clearEndMonth");
    },
    clearParking: ({ commit }) => {
      commit("clearParking");
      commit("clearParkingAddress");
    },
    async setParkingList({ commit }) {
      var parkingList = await axios.get(`${ApiHost}/moove/api/parking`);
      commit("setParkingList", parkingList.data);
    },
    async setParking({ commit, dispatch }, payload) {
      var parking = await axios.get(`${ApiHost}/moove/api/parking/` + payload);
      var coordinate = [
        parseFloat(parking.data.xCrd),
        parseFloat(parking.data.yCrd),
      ];
      await dispatch("setParkingAddress", coordinate);
      await dispatch("setToggleMapOverlay", true);
      commit("setParking", parking.data);
    },
    setToggleMapOverlay: ({ commit }, payload) => {
      commit("setToggleMapOverlay", payload);
    },
    async deleteParkingByParklSeq({ commit }, payload) {
      const params = new URLSearchParams();
      params.append("parklSeq", payload);
      var parkingList = await axios.delete(`${ApiHost}/moove/api/parking`, {
        params: params,
      });
      commit("setParkingList", parkingList.data);
    },
    async updateParkingByParklSeq({ commit }, payload) {
      var parkingList = await axios.put(
        `${ApiHost}/moove/api/parking`,
        payload
      );
      commit("setParkingList", parkingList.data);
    },
    setSearchParklName: ({ commit }, payload) => {
      commit("setSearchParklName", payload);
    },
    setMapClickEvent: ({commit}, payload) => {
      commit("setMapClickEvent", payload);
    },
    setSpinner: ({commit}, payload) => {
      commit("setSpinner", payload);
    },
  },
});

export default store;
