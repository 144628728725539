
// 키 만료 : 2023-04-04
export const VWORLD_KEY = "D3E3F5E1-5A98-379D-A06B-C25D2EAA1043";

/**
 * @see https://www.vworld.kr/dev/v4dv_geocoderguide2_s001.do
 * 주소를 좌표로 변환
 *
 * @param address example: 경기도 성남시 분당구 운중로 135
 * @param addressType R: 도로명  J: 지번
 * @returns {string}
 */
export var getCoordUrl = function (address, addressType="R") {

  let type;
  switch (addressType) {
    case "R":
      type = "ROAD";
      break;
    case "J":
      type = "PARCEL";
      break;
  }

  return `https://api.vworld.kr/req/address?service=address&request=GetCoord&version=2.0` +
      `&key=${VWORLD_KEY}` +
      `&address=${address}&errorFormat=json&type=${type}`;

}

/**
 * @see https://www.vworld.kr/dev/v4dv_geocoderguide2_s002.do
 * 좌표를 주소로 변환
 *
 * @param coordinate example: 126.978275264,37.566642192
 * @param addressType P: 지번  R: 도로명  B: Both
 * @returns {string}
 */
export var getAddressUrl = function (coordinate, addressType="B") {

  let type;
  switch (addressType) {
    case "P":
      type = "PARCEL";
      break;
    case "R":
      type = "ROAD";
      break;
    case "B":
      type = "BOTH";
      break;
  }

  return `https://api.vworld.kr/req/address?service=address&request=GetAddress&version=2.0` +
      `&crs=epsg:4326&point=${coordinate}&format=json&type=${type}` +
      `&zipcode=true&simple=false&key=${VWORLD_KEY}`;
}

export var isEmpty = function(obj, value) {
  if (
    obj == "" ||
    obj == null ||
    obj == undefined ||
    (obj != null && typeof obj == "object" && !Object.keys(obj).length)
  ) {
    return value;
  } else {
    return obj;
  }
};

export var emptyCheck = function (arg) {
  return arg == "" || arg == null || (typeof arg == "object" && !Object.keys(arg).length);
};

/**
 * @description 매개변수 중 하나라도 비어있으면 true 반환
 * @param args
 * @returns {boolean}
 */
export var multiEmptyCheck = function (...args) {
  let result = false;
  for (let i = 0; i < args.length; i++) {
    if (emptyCheck(args[i])) {
      result = true;
      break;
    }
  }
  return result;
}

/**
 * @description 매개변수가 데이터 타입 상관없이 값이 전부 같으면 true 반환
 * @example equalCheck("123", 123, "123") >>> true
 * @param args
 * @return boolean
 */
export var equalCheck = function (...args) {
  let result = true;
  out : for (let i = 0; i < args.length - 1; i++) {
    for (let j = i + 1; j < args.length; j++) {
      if (args[i] != args[j]) {
        result = false;
        break out;
      }
    }
  }
  return result;
}

/**
 * @description 매개변수가 데이터 타입 및 값이 전부 같으면 true 반환
 * @example equalCheck("456", 456, "456") >>> false
 * @param args
 * @return boolean
 */
export var strictEqualCheck = function (...args) {
  let result = true;
  out : for (let i = 0; i < args.length - 1; i++) {
    for (let j = i + 1; j < args.length; j++) {
      if (args[i] !== args[j]) {
        result = false;
        break out;
      }
    }
  }
  return result;
}

/**
 * @description target 기준으로 array 안의 요소와 일치 할 경우 true 반환
 * @description orEqual: ||연산,  andEqual: &&연산
 * @example CheckTrue("abc").orEqual("a", "abc", "c")  >> true
 * @example CheckTrue("abc").andEqual("a", "abc", "c") >> false
 * @param target
 * @return {{orEqualStrict: (function(*): boolean), andEqual: (function(*): boolean), andEqualStrict: (function(*): boolean), orEqual: (function(*): boolean)}}
 * @constructor
 */
export var CheckTrue = function (target) {
  let result = false;
  return CheckTrue.prototype = {
    orEqual: function (array) {
      for (let i = 0; i < array.length; i++) {
        if (target == array[i]) {
          result = true;
          break;
        }
      }
      return result;
    },
    orEqualStrict: function (array) {
      for (let i = 0; i < array.length; i++) {
        if (target === array[i]) {
          result = true;
          break;
        }
      }
      return result;
    },
    andEqual: function (array) {
      for (let i = 0; i < array.length; i++) {
        if (target != array[i]) {
          result = false;
          break;
        } else {
          result = true;
        }
      }
      return result;
    },
    andEqualStrict: function (array) {
      for (let i = 0; i < array.length; i++) {
        if (target !== array[i]) {
          result = false;
          break;
        } else {
          result =  true;
        }
      }
      return result;
    },
  }
};

/**
 * @description Object 를 sessionStorage 에 저장하는 함수
 * @example jsonSessionStorage().get("abc");   jsonSessionStorage().set("abc", {name: "lee"});
 * @return {{set: (function(*, *): *), get: (function(*): any)}}
 */
export var jsonSessionStorage = function() {
  return jsonSessionStorage.prototype = {
    set: function (key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
      return value;
    },
    get: function (key) {
      return JSON.parse(sessionStorage.getItem(key));
    }
  }
};

/**
 * @description Object 를 localStorage 에 저장하는 함수
 * @example jsonLocalStorage().get("abc");   jsonLocalStorage().set("abc", {name: "lee"});
 * @return {{set: jsonLocalStorage.set, get: (function(*): any)}}
 */
export var jsonLocalStorage = function() {
  return jsonLocalStorage.prototype = {
    set: function (key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    get: function (key) {
      return JSON.parse(localStorage.getItem(key));
    }
  }
};

export var formatSecondsAsTime = function(secs, format) {
  var hr = Math.floor(secs / 3600);
  var min = Math.floor((secs - hr * 3600) / 60);
  var sec = Math.floor(secs - hr * 3600 - min * 60);
  if (hr < 10) {
    hr = "0" + hr;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }
  if (format != null) {
    var formatted_time = format.replace("hh", hr);
    formatted_time = formatted_time.replace("h", hr * 1 + ""); // check for single hour formatting
    formatted_time = formatted_time.replace("mm", min);
    formatted_time = formatted_time.replace("m", min * 1 + ""); // check for single minute formatting
    formatted_time = formatted_time.replace("ss", sec);
    formatted_time = formatted_time.replace("s", sec * 1 + ""); // check for single second formatting
    return formatted_time;
  } else {
    return hr + ":" + min + ":" + sec;
  }
};

export var sliceDate = function(dateTime) {
  var time =
    dateTime.slice(2, 4) +
    "-" +
    dateTime.slice(4, 6) +
    "-" +
    dateTime.slice(6, 8);
  return time;
};

export var sliceTime = function(dateTime) {
  var time =
    dateTime.slice(8, 10) +
    ":" +
    dateTime.slice(10, 12) +
    ":" +
    dateTime.slice(12, 14);
  return time;
};

/**
 * @description d가 null 이면 현재 시간 반환
 * @param d Date()
 * @return YYYY-MM-DD HH:MM:SS
 */
export var getCurrentTime = function (d) {
  let nowDate = d;
  if (!(d instanceof Date)) nowDate = new Date();
  const year = nowDate.getFullYear();
  const month = nowDate.getMonth() + 1;
  const date = nowDate.getDate();
  const hour = nowDate.getHours();
  const min = nowDate.getMinutes();
  const sec = nowDate.getSeconds();
  return year + "-" + addZero(month) + "-" + addZero(date) +
      " " + addZero(hour) + ":" + addZero(min) + ":" + addZero(sec);
}

/**
 * @description 현재일 기준 이전 index 개월까지 년월 반환
 * @param index
 * @param sortAsc 오름차순 (false = 내림차순)
 * @return YYYY년 MM월
 */
export var getYearMonthFromNow = function (index = 6, sortAsc = true) {
  let result = [];
  let resultDate;
  for (let i = 0; i <= index; i++) {
    let d = new Date();
    resultDate = new Date(d.setMonth(d.getMonth() - i));
    const year = resultDate.getFullYear();
    const month = addZero(resultDate.getMonth() + 1);
    result.push(`${year}년 ${month}월`);
  }
  if (sortAsc) result.sort();
  return result;
}

function addZero(num) {
  if (num < 10) {
    num = "0" + num;
  }
  return num;
}

/**
 * @description Milliseconds -> Date
 * @param m milliSeconds
 * @return YYYY-MM-DD HH:MM:SS
 */
export var milliSecondsToDate = function (m) {
  let _date = new Date(m);
  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  const date = _date.getDate();
  const hour = _date.getHours();
  const min = _date.getMinutes();
  const sec = _date.getSeconds();
  return year + "-" + addZero(month) + "-" + addZero(date) +
      " " + addZero(hour) + ":" + addZero(min) + ":" + addZero(sec);
}

/**
 * @description Milliseconds -> hh:mm:ss
 * @param m milliSeconds
 */
export var milliSecondsToTime = function (m) {
  let _date = new Date(m);
  const hour = _date.getHours();
  const min = _date.getMinutes();
  const sec = _date.getSeconds();
  return addZero(hour) + ":" + addZero(min) + ":" + addZero(sec);
}

/**
 * @description Milliseconds -> Date
 * @param m milliSeconds
 * @return YYYY-MM-DD HH:MM
 */
export var milliSecondsToDateWithoutSeconds = function (m) {
  let _date = new Date(m);
  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  const date = _date.getDate();
  const hour = _date.getHours();
  const min = _date.getMinutes();
  return year + "-" + addZero(month) + "-" + addZero(date) +
      " " + addZero(hour) + ":" + addZero(min);
}

/**
 * @description yyyyMMddHHmmSS -> yyyy-MM-dd HH:mm:SS
 * @example reformatStringDate(stringValue, {hideTime: true, dateSeparator: "/"});
 * @param s String
 * @param showSeconds 초 표시
 * @param hideTime 시간 숨김
 * @param hideDate 날짜 숨김
 * @param dateSeparator 날짜 구분자
 * @param timeSeparator 시간 구분자
 * @returns {null|string}
 */
export var reformatStringDate = function (s,
                                          {showSeconds = true,
                                          hideTime = false,
                                          hideDate = false,
                                          dateSeparator = "-",
                                          timeSeparator = ":"}
) {
  if (s.length !== 14 || (hideTime && hideDate)) {
    return null;
  }

  const year = s.substring(0, 4);
  const month = s.substring(4, 6);
  const day = s.substring(6, 8);
  const hour = s.substring(8, 10);
  const minute = s.substring(10, 12);
  const seconds = s.substring(12, 14);

  if (hideTime) {
    return year + dateSeparator + month + dateSeparator + day;
  }

  if (hideDate) {
    if (showSeconds) {
      return hour + timeSeparator + minute + timeSeparator + seconds;
    } else {
      return hour + timeSeparator + minute;
    }
  }

  if (showSeconds) {
    return year + dateSeparator + month + dateSeparator + day + " " + hour + timeSeparator + minute + timeSeparator + seconds;
  } else {
    return year + dateSeparator + month + dateSeparator + day + " " + hour + timeSeparator + minute;
  }
}

export var getWeekday = function (v) {
  let num = emptyCheck(v) ? new Date().getDay() : v;
  switch (num) {
    case 0:
      return "일요일";
    case 1:
      return "월요일";
    case 2:
      return "화요일";
    case 3:
      return "수요일";
    case 4:
      return "목요일";
    case 5:
      return "금요일";
    case 6:
      return "토요일";
  }
}
