import axios from "axios";
import { ApiHost } from "../constants";
// import { formatSecondsAsTime } from "@/utils/util.js";

export default {
  state: {
    selectedCarNo: "",
    selectedDept: {},
    toggleDayMonth: 0,
    driveRecordSummaryList: [],
    driveRecordDayList: [],
    driveRecordTripList: [],
    driveHistorySummaryList: [],
    driveHistoryDayList: [],
    driveHistoryTripList: [],
    driveAnalysisScoreList: [],
    driverAnalysisScoreList: [],
    driveRecordListByCarList: [],
    breakTimeList: [],
    driveEventFilter: [],
    driveAnalysisScoreSummaryList: [],
    driverAnalysisScoreSummaryList: [],
    driveEventList: [],
    startTime: "01시",
    endTime: "24시",
  },
  getters: {
    getSelectedCarNo: (state) => {
      return state.selectedCarNo;
    },
    getSelectedDept: (state) => {
      return state.selectedDept;
    },
    getToggleDayMonth: (state) => {
      return state.toggleDayMonth;
    },
    getDriveRecordSummaryList: (state) => {
      return state.driveRecordSummaryList;
    },
    getDriveRecordDayList: (state) => {
      return state.driveRecordDayList;
    },
    getDriveRecordTripList: (state) => {
      return state.driveRecordTripList;
    },
    getDriveHistorySummaryList: (state) => {
      return state.driveHistorySummaryList;
    },
    getDriveHistoryDayList: (state) => {
      return state.driveHistoryDayList;
    },
    getDriveHistoryTripList: (state) => {
      return state.driveHistoryTripList;
    },
    getDriveAnalysisScoreList: (state) => {
      return state.driveAnalysisScoreList;
    },
    getDriverAnalysisScoreList: (state) => {
      return state.driverAnalysisScoreList;
    },
    getDriveRecordListByCarList: (state) => {
      return state.driveRecordListByCarList;
    },
    getBreakTimeList: (state) => {
      return state.breakTimeList;
    },
    getDriveAnalysisScoreSummaryList: (state) => {
      return state.driveAnalysisScoreSummaryList;
    },
    getDriverAnalysisScoreSummaryList: (state) => {
      return state.driverAnalysisScoreSummaryList;
    },
    getDriveEventList: (state) => {

      let filter = state.driveEventFilter;
      let eventList = state.driveEventList;

      let resultList = [];

      filter.forEach(f => {
        resultList.push(eventList.filter(v => v.evtTp === f));
      });

      return resultList;

    },
    getStartTime: (state) => {
      return state.startTime;
    },
    getEndTime: (state) => {
      return state.endTime;
    },
  },
  mutations: {
    setSelectedCarNo: (state, payload) => {
      state.selectedCarNo = payload;
    },
    setSelectedDept: (state, payload) => {
      state.selectedDept = payload;
    },
    setToggleDayMonth: (state, payload) => {
      state.toggleDayMonth = payload;
    },
    setDriveRecordSummaryList: (state, payload) => {
      state.driveRecordSummaryList = payload;
    },
    setDriveRecordDayList: (state, payload) => {
      state.driveRecordDayList = payload;
    },
    setDriveRecordTripList: (state, payload) => {
      state.driveRecordTripList = payload;
    },
    setDriveHistorySummaryList: (state, payload) => {
      state.driveHistorySummaryList = payload;
    },
    setDriveHistoryDayList: (state, payload) => {
      state.driveHistoryDayList = payload;
    },
    setDriveHistoryTripList: (state, payload) => {
      state.driveHistoryTripList = payload;
    },
    setDriveAnalysisScoreList: (state, payload) => {
      state.driveAnalysisScoreList = payload;
    },
    setDriverAnalysisScoreList: (state, payload) => {
      state.driverAnalysisScoreList = payload;
    },
    setDriveRecordListByCarList: (state, payload) => {
      state.driveRecordListByCarList = payload;
    },
    setBreakTimeList: (state, payload) => {
      state.breakTimeList = payload;
    },
    setDriveAnalysisScoreSummaryList: (state, payload) => {
      state.driveAnalysisScoreSummaryList = payload;
    },
    setDriverAnalysisScoreSummaryList: (state, payload) => {
      state.driverAnalysisScoreSummaryList = payload;
    },
    clearDriveRecordTripList: (state) => {
      state.driveRecordTripList = [];
    },
    setDriveEventList: (state, payload) => {
       state.driveEventList = payload;
    },
    setDriveEventFilter: (state, payload) => {
      state.driveEventFilter = payload;
    },
    setStartTime: (state, payload) => {
      state.startTime = payload;
    },
    setEndTime: (state, payload) => {
      state.endTime = payload;
    },
    clearDriveRecordSummaryList: (state) => {
      state.driveRecordSummaryList = [];
    },
    clearDriveHistorySummaryList: (state) => {
      state.driveHistorySummaryList = [];
    },
    clearDriveAnalysisScoreSummaryList: (state) => {
   	  state.driveAnalysisScoreSummaryList = [];
    },
    clearDriverAnalysisScoreSummaryList: (state) => {
      state.driverAnalysisScoreSummaryList = [];
    },
    clearBreakTimeList: (state) => {
      state.breakTimeList = [];
    },
    clearDriveAnalysisScoreList: (state) => {
      state.driveAnalysisScoreList = [];
    },
    clearDriverAnalysisScoreList: (state) => {
      state.driverAnalysisScoreList = [];
    },
  },
  actions: {
    setSelectedCarNo: ({ commit }, payload) => {
      commit("setSelectedCarNo", payload);
    },
    setSelectedDept: ({ commit }, payload) => {
      commit("setSelectedDept", payload);
    },
    setToggleDayMonth: ({ commit }, payload) => {
      commit("setToggleDayMonth", payload);
    },
    async setDriveRecordSummaryList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var driveRecordSummaryList = await axios.get(
        `${ApiHost}/moove/api/drvanl/drvrcd/summary/` +
        // `${ApiHost}/moove/api/drvanl/drvrcd/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay,
        { params: params }
      );
      commit("setDriveRecordSummaryList", driveRecordSummaryList.data);
    },
    async setDriveRecordDayList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) params.append("carNo", payload.carNo);
      if (payload.dptCd !== undefined) params.append("dptCd", payload.dptCd);
      var driveRecordDayList = await axios.get(
        `${ApiHost}/moove/api/drvanl/drvrcd/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay,
        { params: params }
      );

      Promise.all(
        driveRecordDayList.data.map(async function (ele) {
          var bgnBody = {
            x: ele.bgnX,
            y: ele.bgnY
          };
          var endBody = {
            x: ele.endX,
            y: ele.endY
          };
          var ele1 = {
            ...ele,
            bgnAddress: "-",
            endAddress: "-"
          }
          await axios.post(`${ApiHost}/moove/api/geocoding/reverse`, bgnBody).then((res) => {
            if(res.data === "") return ele1.bgnAddress  = "-"
            ele1.bgnAddress = res.data;
          });
          await axios.post(`${ApiHost}/moove/api/geocoding/reverse`, endBody).then((res) => {
            if(res.data === "") return ele1.endAddress  = "-"
            ele1.endAddress = res.data;
          });
          return ele1;
        })
      ).then((val) =>{
        commit("setDriveRecordDayList", val);
      });

      commit("setDriveRecordDayList", driveRecordDayList.data);
    },
    async setDriveRecordTripList({ commit }, payload) {
      var driveRecordTripList = await axios.get(
        `${ApiHost}/moove/api/car/drvrcd-trp/` +
          payload.carNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay
      );

      Promise.all(
        driveRecordTripList.data.map(async function (ele) {
          var bgnBody = {
            x: ele.bgnX,
            y: ele.bgnY,
          };
          var endBody = {
            x: ele.endX,
            y: ele.endY,
          };
          var ele1 = {
            ...ele,
            bgnAddress: "-",
            endAddress: "-",
          };

          await axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, bgnBody)
            .then((res) => {
              if(res.data === "") return ele1.bgnAddress  = "-"
              ele1.bgnAddress = res.data;
            });
          await axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, endBody)
            .then((res) => {
              if(res.data === "") return ele1.endAddress  = "-"
              ele1.endAddress = res.data;
            });
          return ele1;
        })
      ).then((val) => {
        commit("setDriveRecordTripList", val);
      });
      commit("setDriveRecordTripList", driveRecordTripList.data);
    },
    async setDriveHistorySummaryList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var driveHistorySummaryList = await axios.get(
        // `${ApiHost}/moove/api/drvanl/drvrcd/summary/` +
        `${ApiHost}/moove/api/drvanl/drvrcd/summary/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay,
        { params: params }
      );
      commit("setDriveHistorySummaryList", driveHistorySummaryList.data);
    },
    async setDriveHistoryDayList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) params.append("carNo", payload.carNo);
      if (payload.dptCd !== undefined) params.append("dptCd", payload.dptCd);
      var driveHistoryDayList = await axios.get(
        `${ApiHost}/moove/api/drvanl/drvrcd/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay,
        { params: params }
      );

      Promise.all(
        driveHistoryDayList.data.map(async function (ele) {
          var bgnBody = {
            x: ele.bgnX,
            y: ele.bgnY
          };
          var endBody = {
            x: ele.endX,
            y: ele.endY
          };
          var ele1 = {
            ...ele,
            bgnAddress: "-",
            endAddress: "-"
          }
          await axios.post(`${ApiHost}/moove/api/geocoding/reverse`, bgnBody).then((res) => {
            if(res.data === "") return ele1.bgnAddress  = "-"
            ele1.bgnAddress = res.data;
          });
          await axios.post(`${ApiHost}/moove/api/geocoding/reverse`, endBody).then((res) => {
            if(res.data === "") return ele1.endAddress  = "-"
            ele1.endAddress = res.data;
          });
          return ele1;
        })
      ).then((val) =>{
        commit("setDriveHistoryDayList", val);
      });

      commit("setDriveHistoryDayList", driveHistoryDayList.data);
    },
    async setDriveHistoryTripList({ commit }, payload) {
      var driveHistoryTripList = await axios.get(
        `${ApiHost}/moove/api/car/drvrcd-trp/` +
          payload.carNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay
      );

      Promise.all(
        driveHistoryTripList?.data.map(async function (ele) {
          var bgnBody = {
            x: ele.bgnX,
            y: ele.bgnY,
          };
          var endBody = {
            x: ele.endX,
            y: ele.endY,
          };
          var ele1 = {
            ...ele,
            bgnAddress: "-",
            endAddress: "-",
          };

          await axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, bgnBody)
            .then((res) => {
              if(res.data === "") return ele1.bgnAddress  = "-"
              ele1.bgnAddress = res.data;
            });
          await axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, endBody)
            .then((res) => {
              if(res.data === "") return ele1.endAddress  = "-"
              ele1.endAddress = res.data;
            });
          return ele1;
        })
      ).then((val) => {
        commit("setDriveHistoryTripList", val);
      });
      commit("setDriveHistoryTripList", driveHistoryTripList.data);
    },
    async setDriveAnalysisScoreList({ commit }, payload) {
      var driveAnalysisScoreList = await axios.get(
        `${ApiHost}/moove/api/drvanl/drvscr/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay +
          `?carNo=` +
          payload.carNo +
          `&dptId=` +
          payload.dptId
      );
      commit("setDriveAnalysisScoreList", driveAnalysisScoreList.data);
    },
    async setDriverAnalysisScoreList({ commit }, payload) {
      var driverAnalysisScoreList = await axios.get(
        `${ApiHost}/moove/api/drvranl/drvrscr/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay +
          `?drvrId=` +
          payload.drvrId +
          `&dptId=` +
          payload.dptId
      );
      commit("setDriverAnalysisScoreList", driverAnalysisScoreList.data);
    },

    async setDriveRecordListByCarList({ commit }, payload) {
      var driveRecordListByCarList = [];
      await payload.carNo.forEach(async function(ele) {
        var drvrcdList = await axios.get(
          `${ApiHost}/moove/api/drvanl/drvrcd/` +
            payload.bizNo +
            `/` +
            payload.startDay +
            `/` +
            payload.endDay +
            `?carNo=` +
            ele.carNo +
            `&dptId=` +
            payload.dptId
        );
        driveRecordListByCarList.push({
          bizNo: payload.bizNo,
          dptId: payload.dptId,
          carNo: ele.carNo,
          carNm: ele.carNm,
          recordBookList: drvrcdList.data,
        });
        await commit("setDriveRecordListByCarList", driveRecordListByCarList);
      }, this);
    },
    async setBreakTimeList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var breakTimeList = await axios.get(
        `${ApiHost}/moove/api/drvanl/brktm/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay,
        { params: params }
      );
      await commit("setBreakTimeList", breakTimeList.data);
    },

    async setDriveAnalysisScoreSummaryList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.carNo !== null) {
        if (payload.carNo !== undefined) {
          params.append("carNo", payload.carNo);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var driveAnalysisScoreSummaryList = await axios.get(
        `${ApiHost}/moove/api/drvanl/drvscr/summary/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay,
        { params: params }
      );
      commit(
        "setDriveAnalysisScoreSummaryList",
        driveAnalysisScoreSummaryList.data
      );
    },
    async setDriverAnalysisScoreSummaryList({ commit }, payload) {
      const params = new URLSearchParams();
      if (payload.drvrNm !== null) {
        if (payload.drvrNm !== undefined) {
          params.append("drvrNm", payload.drvrNm);
        }
      }
      if (payload.dptCd !== null) {
        if (payload.dptCd !== undefined) {
          params.append("dptCd", payload.dptCd);
        }
      }
      var driverAnalysisScoreSummaryList = await axios.get(
        `${ApiHost}/moove/api/drvranl/drvrscr/summary/` +
          payload.bizNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay,
        { params: params }
      );
      commit(
        "setDriverAnalysisScoreSummaryList",
        driverAnalysisScoreSummaryList.data
      );
    },
    clearDriveRecordTripList: ({ commit }) => {
      return commit("clearDriveRecordTripList");
    },
    async setDriveEventList({ commit }, payload) {
      var driveEventList = await axios.get(
        `${ApiHost}/moove/api/car/drvevt/` +
          payload.carNo +
          `/` +
          payload.trpSeq
      );
      commit("setDriveEventFilter", payload.type);
      commit("setDriveEventList", driveEventList.data);
    },
    async setDriveRecordTripListByPage({ commit }, payload) {
      var driveRecordTripList = await axios.get(
        `${ApiHost}/moove/api/car/drvrcd-trp/` +
          payload.carNo +
          `/` +
          payload.startDay +
          `/` +
          payload.endDay +
          `/page/` +
          payload.startPage +
          `/` +
          payload.endPage
      );
      Promise.all(
        driveRecordTripList.data.elements.map(async function(ele) {
          var bgnBody = {
            x: ele.bgnX,
            y: ele.bgnY,
          };
          var endBody = {
            x: ele.endX,
            y: ele.endY,
          };
          var ele1 = {
            ...ele,
            bgnAddress: "-",
            endAddress: "-",
          };

          await axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, bgnBody)
            .then((res) => {
              if(res.data === "") return ele1.bgnAddress  = "-"
              ele1.bgnAddress = res.data;
            });
          await axios
            .post(`${ApiHost}/moove/api/geocoding/reverse`, endBody)
            .then((res) => {
              if(res.data === "") return ele1.endAddress  = "-"
              ele1.endAddress = res.data;
            });
          return ele1;
        })
      ).then((val) => {
        commit("setDriveRecordTripList", val);
      });
      commit("setDriveRecordTripList", driveRecordTripList.data);
    },
    setStartTime: ({ commit }, payload) => {
      commit("setStartTime", payload);
    },
    setEndTime: ({ commit }, payload) => {
      commit("setEndTime", payload);
    },
    clearDriveRecordSummaryList: ({ commit }) => {
      commit("clearDriveRecordSummaryList");
    },
    clearDriveHistorySummaryList: ({ commit }) => {
      commit("clearDriveHistorySummaryList");
    },
    clearDriveAnalysisScoreSummaryList: ({ commit }) => {
      commit("clearDriveAnalysisScoreSummaryList");
    },
    clearDriverAnalysisScoreSummaryList: ({ commit }) => {
      commit("clearDriverAnalysisScoreSummaryList");
    },
    clearBreakTimeList: ({ commit }) => {
      commit("clearBreakTimeList");
    },
    clearDriveAnalysisScoreList: ({ commit }) => {
      commit("clearDriveAnalysisScoreList");
    },
    clearDriverAnalysisScoreList: ({ commit }) => {
      commit("clearDriverAnalysisScoreList");
    },
  },
};
