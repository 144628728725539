import axios from "axios";
import { ApiHost } from "../constants";

export default {
  state: {
    mbrLcns: {},
  },

  getters: {
    getMbrLcns: (state) => {
      return state.mbrLcns;
    },
  },

  mutations: {
    setMbrLcns: (state, payload) => {
      state.mbrLcns = payload;
    },
  },
  
  actions: {
    // 운전면허 저장
    async saveMbrLcns({commit}, payload) {
      const result = await axios.post(`${ApiHost}/moove/api/member/license/save`, payload);
      if (result.data.statusCode !== 200) console.error(result.data.message);
      return result.data;
    },
    // 운전면허 조회
    async setMbrLcns ({commit}, payload) {
      await axios.get(`${ApiHost}/moove/api/member/license/${payload}`).then(v => {
    	if (v.data.length == 0) {
    		commit("setMbrLcns", {lgnId: payload, lcnsId:"", lcnsKind:""});
    	}
        else { 
        	commit("setMbrLcns", v.data);
        }
      });
    },

    // 로그인 2차 인증 문자 발송
    async sendAuthCode({commit}, payload) {
      const result = await axios.post(`${ApiHost}/moove/api/member/sendAuthCode`, payload);
      if (result.status !== 200) console.error(result.statusText);
      return result.data;
    },
  },
};
