import axios from 'axios';
import {ApiHost} from '../constants'

export default {

  state: {
    faqTypeList: [
      { name: '서비스', faqType: '01' },
      { name: '단말', faqType: '02' },
      { name: '청약', faqType: '03' },
      { name: '기타',  faqType: '04' }
    ],
    attFileList: [],
    faq: {},
    faqList: [],
    preNxtFaqList: [],
  },

  getters: {
    getFaq: state => {
      return state.faq;
    },
    getFaqList: state => {
      if(state.faqList.length==0) return state.faqList;

      return state.faqList.map(function(ele) {
      var faqType = state.faqTypeList.filter(n=> n.faqType == ele.faqType);

      var ele1 = {
        ...ele,
        faqTypeNm: faqType.map((c)=>c.name)
      };
      return ele1;
      });
    },
    getAttFileList: state => {
      if(state.attFileList.length==0) return state.attFileList;
      return state.attFileList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.fileId
        };
        return ele1;
      });
    },
    getFaqTypeList: state => {
      return state.faqTypeList;
    },
    getPreNxtFaqList: state => {
      return state.preNxtFaqList;
    },
  },

  mutations: {
    setFaq: (state, payload) => {
      var faqType = state.faqTypeList.filter(n=> n.faqType == payload.faqType);
      state.faq = {
        ...payload,
        faqTypeNm: faqType.map((c)=>c.name)[0]
      };
    },
    setFaqList: (state, payload) => {
      if(payload == '') payload = [];
      state.faqList = payload;
    },
    setFaqTypeList: (state, payload) => {
      state.faqTypeList = payload;
    },
    setAttFileList: (state, payload) => {
      state.attFileList = payload;
    },
    setPreNxtFaqList: (state, payload) => {
      state.preNxtFaqList = payload;
    },
  },

  actions: {
    setFaq: ({ commit }, payload) => {
      axios.get(`${ApiHost}/moove/api/faq/` + payload.faqId).then(res => {
        commit('setFaq', res.data);
      });
    },
    setFaqList: ({ commit }) => {
      axios.get(`${ApiHost}/moove/api/faq`).then(res => {
        commit('setFaqList', res.data);
      });
    },
    async loadFaqAttFileList({ commit }, payload) {
      var attFileList = await axios.get(
        `${ApiHost}/moove/api/faq/attfile/` + payload.faqId
      );
      commit('setAttFileList', attFileList.data);
    },
    async setPreNxtFaqList({ commit }, payload) {
      var preNxtFaqList = await axios.get(
        `${ApiHost}/moove/api/faq/` + payload.faqId + '/prenxt'
      );
      commit('setPreNxtFaqList', preNxtFaqList.data);
    },
  }

}
