import axios from "axios";
import { ApiHost } from "../constants";

export default {
  state: {
    introAttFileList: [],
    installAttFileList: [],
  },

  getters: {
    getIntroAttFileList: (state) => {
      if (state.introAttFileList.length == 0) return state.introAttFileList;
      return state.introAttFileList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.fileId,
        };
        return ele1;
      });
    },
    getInstallAttFileList: (state) => {
      if (state.installAttFileList.length == 0) return state.installAttFileList;
      return state.installAttFileList.map(function(ele) {
        var ele1 = {
          ...ele,
          id: ele.fileId,
        };
        return ele1;
      });
    },
  },

  mutations: {
    setIntroAttFileList: (state, payload) => {
      state.introAttFileList = payload;
    },
    setInstallAttFileList: (state, payload) => {
      state.installAttFileList = payload;
    },
  },

  actions: {
    async deleteArchiveAttFile({ commit }, payload) {
      await axios.delete(`${ApiHost}/moove/api/attfile/` + payload.fileId);
      var attFileList = await axios.get(
        `${ApiHost}/moove/api/archive/attfile/` + payload.boardId
      );

      var setListName = "setIntroAttFileList";
      if (payload.boardId == 2) setListName = "setInstallAttFileList";

      commit(setListName, attFileList.data);
    },
    async updateArchiveAttFileList({ commit }, formData) {
      var boardId = formData.get("boardId");
      await axios
        .post(`${ApiHost}/moove/api/archive`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function() {})
        .catch(function() {});

      var attFileList = await axios.get(
        `${ApiHost}/moove/api/archive/attfile/` + boardId
      );
      var setListName = "setIntroAttFileList";
      if (boardId == 2) setListName = "setInstallAttFileList";

      commit(setListName, attFileList.data);
    },
    async loadIntroAttFileList({ commit }, payload) {
      var attFileList = await axios.get(
        `${ApiHost}/moove/api/archive/attfile/` + payload
      );
      commit("setIntroAttFileList", attFileList.data);
    },
    async loadInstallAttFileList({ commit }, payload) {
      var attFileList = await axios.get(
        `${ApiHost}/moove/api/archive/attfile/` + payload
      );
      commit("setInstallAttFileList", attFileList.data);
    },
  },
};
